/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
:root {
  --white: #fff;
  --black: #0c0d0e;
  --orange: #f48024;
  --yellow: #fbf2d4;
  --green: #5eba7d;
  --blue: #0077cc;
  --powder: #e1ecf4;
  --red: #d1383d;
  --black-025: #fafafb;
  --black-050: #eff0f1;
  --black-075: #e4e6e8;
  --black-100: #d6d9dc;
  --black-150: #c8ccd0;
  --black-200: #bbc0c4;
  --black-300: #9fa6ad;
  --black-350: #9199a1;
  --black-400: #848d95;
  --black-500: #6a737c;
  --black-600: #535a60;
  --black-700: #3c4146;
  --black-750: #2f3337;
  --black-800: #242729;
  --black-900: #0c0d0e;
  --orange-050: #fff7f2;
  --orange-100: #fee3cf;
  --orange-200: #fcd0ad;
  --orange-300: #f7aa6d;
  --orange-400: #f48024;
  --orange-500: #f2720c;
  --orange-600: #da670b;
  --orange-700: #bd5c00;
  --orange-800: #a35200;
  --orange-900: #874600;
  --blue-050: #f2f9ff;
  --blue-100: #cfeafe;
  --blue-200: #addafc;
  --blue-300: #6cbbf7;
  --blue-400: #379fef;
  --blue-500: #0095ff;
  --blue-600: #0077cc;
  --blue-700: #0064bd;
  --blue-800: #0054a3;
  --blue-900: #004487;
  --powder-050: #f4f8fb;
  --powder-100: #e1ecf4;
  --powder-200: #d1e5f1;
  --powder-300: #b3d3ea;
  --powder-400: #a0c7e4;
  --powder-500: #7aa7c7;
  --powder-600: #5b8db1;
  --powder-700: #39739d;
  --powder-800: #2c5777;
  --powder-900: #1e3c52;
  --green-025: #eef8f1;
  --green-050: #dcf0e2;
  --green-100: #cae8d4;
  --green-200: #a6d9b7;
  --green-300: #82ca9a;
  --green-400: #5eba7d;
  --green-500: #48a868;
  --green-600: #3d8f58;
  --green-700: #2f6f44;
  --green-800: #29603b;
  --green-900: #1e472c;
  --yellow-050: #fdf7e3;
  --yellow-100: #fbf2d4;
  --yellow-200: #f1e5bc;
  --yellow-300: #e6d178;
  --yellow-400: #e9c63f;
  --yellow-500: #ddb624;
  --yellow-600: #cea51b;
  --yellow-700: #b89516;
  --yellow-800: #9f8010;
  --yellow-900: #826a0b;
  --red-050: #fdf3f4;
  --red-100: #f9d3d7;
  --red-200: #f4b4bb;
  --red-300: #e87c87;
  --red-400: #de535e;
  --red-500: #d1383d;
  --red-600: #c02d2e;
  --red-700: #ac2726;
  --red-800: #942121;
  --red-900: #7a1819;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --forground-color: #333;
  --blue-40: #45a1ff;
  --blue-40-a10: rgb(69, 161, 255, 0.1);
  --blue-50: #0a84ff;
  --blue-50-a30: rgba(10, 132, 255, 0.3);
  --blue-60: #0060df;
  --blue-70: #003eaa;
  --blue-80: #002275;
  --grey-20: #ededf0;
  --grey-30: #d7d7db;
  --grey-40: #b1b1b3;
  --grey-60: #4a4a4f;
  --grey-90: #0c0c0d;
  --grey-90-a10: rgba(12, 12, 13, 0.1);
  --grey-90-a20: rgba(12, 12, 13, 0.2);
  --grey-90-a30: rgba(12, 12, 13, 0.3);
  --grey-90-a50: rgba(12, 12, 13, 0.5);
  --grey-90-a60: rgba(12, 12, 13, 0.6);
  --green-50: #30e60b;
  --green-60: #12bc00;
  --green-70: #058b00;
  --green-80: #006504;
  --green-90: #003706;
  --orange-50: #ff9400;
  --red-50: #ff0039;
  --red-50-a30: rgba(255, 0, 57, 0.3);
  --red-60: #d70022;
  --red-70: #a4000f;
  --red-80: #5a0002;
  --red-90: #3e0200;
  --yellow-50: #ffe900;
  --yellow-60: #d7b600;
  --yellow-60-a30: rgba(215, 182, 0, 0.3);
  --yellow-70: #a47f00;
  --yellow-80: #715100;
  --yellow-90: #3e2800;
  --shadow-10: 0 1px 4px var(--grey-90-a10);
  --shadow-30: 0 4px 16px var(--grey-90-a10);
  --card-padding: 16px;
  --card-shadow: var(--shadow-10);
  --card-outline-color: var(--grey-30);
  --card-shadow-hover: var(--card-shadow), 0 0 0 5px var(--card-outline-color);
  --gm-background-color: #fff;
  --gm-body-text-color: #3c4043;
  --gm-caption-color: #5f6368;
  --gm-error-color-highlight: #fce8e6;
  --sxr-error-color: #c5221f;
  --gm-hairline-color: #dadce0;
  --gm-hint-color: #80868b;
  --gm-label-color: #5f6368;
  --gm-navigation-icon-color: #3c4043;
  --gm-neutral-highlight-color: #f8f9fa;
  --gm-neutral-outline-color: #dadce0;
  --global-border-radius: 8px;
  --color-1: #333333;
  --color-1-a: rgba(#4c7ebf, 70%);
  --hue-web-blue-50-a10:#fff;
  --color-2: #f25022;
  --color-3: #434242;
  --gradient: linear-gradient(180deg, #F743B6 5.22%, #FCC043 99.75%)
  --scrollbarBG: #a1a1a1;
  --link-color: var(--blue-40);
  --thumbBG: #f8f9fa;
  --ff-sans: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Liberation Sans", sans-serif;
  --ff-serif: Georgia, Cambria, "Times New Roman", Times, serif;
  --ff-mono: ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Liberation Mono",
    Menlo, Monaco, Consolas, monospace;
  --ff-iransans: IRANSans;
  --theme-body-font-family: var(--ff-sans);
  --body-background-color: #f3f3f3;
}

.color1 {
  color: #4c7ebf;
}

.color2 {
  color: #3fdbeb;
}

.color3 {
  color: var(--third-color);
}

.d-flex {
  display: -ms-flexbox;
  display: flex;
}

.d-inline-flex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.justify-content-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-content-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-items-end {
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-items-center {
  -ms-flex-align: center;
  align-items: center;
}

.align-items-baseline {
  -ms-flex-align: baseline;
  align-items: baseline;
}

.align-items-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-self-center {
  -ms-flex-item-align: center;
  align-self: center;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.flex-fill {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-grow-1 {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.align-content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.align-content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (min-width: 512px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1536px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.form-control {
  padding: 14px 8px;
  border: 1px solid #ced0d9;
  border-radius: 100px;
  width: 100%;
}

.grid {
  display: grid;
}
.grid.grid--fit {
  grid-template-columns: repeat(auto-fit, minmax(var(--data-width), 1fr));
}
.grid.grid--manually {
  grid-template-columns: repeat(12, 1fr);
}
.grid.grid--fill {
  grid-template-columns: repeat(auto-fill, minmax(var(--data-width), 1fr));
}
.grid.grid--gap-025 {
  grid-gap: 0.25rem;
}
.grid.grid--gap-050 {
  grid-gap: 0.5rem;
}
.grid.grid--gap-075 {
  grid-gap: 0.75rem;
}
.grid.grid--gap-1 {
  grid-gap: 1rem;
}
.grid.grid--gap-2 {
  grid-gap: 2rem;
}
.grid .grid__item {
  position: relative;
}

@media (min-width: 0) {
  .grid .xs-col-1-1 {
    grid-column: 1/1;
  }
  .grid .xs-col-1-2 {
    grid-column: 1/2;
  }
  .grid .xs-col-1-3 {
    grid-column: 1/3;
  }
  .grid .xs-col-1-4 {
    grid-column: 1/4;
  }
  .grid .xs-col-1-5 {
    grid-column: 1/5;
  }
  .grid .xs-col-1-6 {
    grid-column: 1/6;
  }
  .grid .xs-col-1-7 {
    grid-column: 1/7;
  }
  .grid .xs-col-1-8 {
    grid-column: 1/8;
  }
  .grid .xs-col-1-9 {
    grid-column: 1/9;
  }
  .grid .xs-col-1-10 {
    grid-column: 1/10;
  }
  .grid .xs-col-1-11 {
    grid-column: 1/11;
  }
  .grid .xs-col-1-12 {
    grid-column: 1/12;
  }
  .grid .xs-col-1-13 {
    grid-column: 1/13;
  }
  .grid .xs-col-2-1 {
    grid-column: 2/1;
  }
  .grid .xs-col-2-2 {
    grid-column: 2/2;
  }
  .grid .xs-col-2-3 {
    grid-column: 2/3;
  }
  .grid .xs-col-2-4 {
    grid-column: 2/4;
  }
  .grid .xs-col-2-5 {
    grid-column: 2/5;
  }
  .grid .xs-col-2-6 {
    grid-column: 2/6;
  }
  .grid .xs-col-2-7 {
    grid-column: 2/7;
  }
  .grid .xs-col-2-8 {
    grid-column: 2/8;
  }
  .grid .xs-col-2-9 {
    grid-column: 2/9;
  }
  .grid .xs-col-2-10 {
    grid-column: 2/10;
  }
  .grid .xs-col-2-11 {
    grid-column: 2/11;
  }
  .grid .xs-col-2-12 {
    grid-column: 2/12;
  }
  .grid .xs-col-2-13 {
    grid-column: 2/13;
  }
  .grid .xs-col-3-1 {
    grid-column: 3/1;
  }
  .grid .xs-col-3-2 {
    grid-column: 3/2;
  }
  .grid .xs-col-3-3 {
    grid-column: 3/3;
  }
  .grid .xs-col-3-4 {
    grid-column: 3/4;
  }
  .grid .xs-col-3-5 {
    grid-column: 3/5;
  }
  .grid .xs-col-3-6 {
    grid-column: 3/6;
  }
  .grid .xs-col-3-7 {
    grid-column: 3/7;
  }
  .grid .xs-col-3-8 {
    grid-column: 3/8;
  }
  .grid .xs-col-3-9 {
    grid-column: 3/9;
  }
  .grid .xs-col-3-10 {
    grid-column: 3/10;
  }
  .grid .xs-col-3-11 {
    grid-column: 3/11;
  }
  .grid .xs-col-3-12 {
    grid-column: 3/12;
  }
  .grid .xs-col-3-13 {
    grid-column: 3/13;
  }
  .grid .xs-col-4-1 {
    grid-column: 4/1;
  }
  .grid .xs-col-4-2 {
    grid-column: 4/2;
  }
  .grid .xs-col-4-3 {
    grid-column: 4/3;
  }
  .grid .xs-col-4-4 {
    grid-column: 4/4;
  }
  .grid .xs-col-4-5 {
    grid-column: 4/5;
  }
  .grid .xs-col-4-6 {
    grid-column: 4/6;
  }
  .grid .xs-col-4-7 {
    grid-column: 4/7;
  }
  .grid .xs-col-4-8 {
    grid-column: 4/8;
  }
  .grid .xs-col-4-9 {
    grid-column: 4/9;
  }
  .grid .xs-col-4-10 {
    grid-column: 4/10;
  }
  .grid .xs-col-4-11 {
    grid-column: 4/11;
  }
  .grid .xs-col-4-12 {
    grid-column: 4/12;
  }
  .grid .xs-col-4-13 {
    grid-column: 4/13;
  }
  .grid .xs-col-5-1 {
    grid-column: 5/1;
  }
  .grid .xs-col-5-2 {
    grid-column: 5/2;
  }
  .grid .xs-col-5-3 {
    grid-column: 5/3;
  }
  .grid .xs-col-5-4 {
    grid-column: 5/4;
  }
  .grid .xs-col-5-5 {
    grid-column: 5/5;
  }
  .grid .xs-col-5-6 {
    grid-column: 5/6;
  }
  .grid .xs-col-5-7 {
    grid-column: 5/7;
  }
  .grid .xs-col-5-8 {
    grid-column: 5/8;
  }
  .grid .xs-col-5-9 {
    grid-column: 5/9;
  }
  .grid .xs-col-5-10 {
    grid-column: 5/10;
  }
  .grid .xs-col-5-11 {
    grid-column: 5/11;
  }
  .grid .xs-col-5-12 {
    grid-column: 5/12;
  }
  .grid .xs-col-5-13 {
    grid-column: 5/13;
  }
  .grid .xs-col-6-1 {
    grid-column: 6/1;
  }
  .grid .xs-col-6-2 {
    grid-column: 6/2;
  }
  .grid .xs-col-6-3 {
    grid-column: 6/3;
  }
  .grid .xs-col-6-4 {
    grid-column: 6/4;
  }
  .grid .xs-col-6-5 {
    grid-column: 6/5;
  }
  .grid .xs-col-6-6 {
    grid-column: 6/6;
  }
  .grid .xs-col-6-7 {
    grid-column: 6/7;
  }
  .grid .xs-col-6-8 {
    grid-column: 6/8;
  }
  .grid .xs-col-6-9 {
    grid-column: 6/9;
  }
  .grid .xs-col-6-10 {
    grid-column: 6/10;
  }
  .grid .xs-col-6-11 {
    grid-column: 6/11;
  }
  .grid .xs-col-6-12 {
    grid-column: 6/12;
  }
  .grid .xs-col-6-13 {
    grid-column: 6/13;
  }
  .grid .xs-col-7-1 {
    grid-column: 7/1;
  }
  .grid .xs-col-7-2 {
    grid-column: 7/2;
  }
  .grid .xs-col-7-3 {
    grid-column: 7/3;
  }
  .grid .xs-col-7-4 {
    grid-column: 7/4;
  }
  .grid .xs-col-7-5 {
    grid-column: 7/5;
  }
  .grid .xs-col-7-6 {
    grid-column: 7/6;
  }
  .grid .xs-col-7-7 {
    grid-column: 7/7;
  }
  .grid .xs-col-7-8 {
    grid-column: 7/8;
  }
  .grid .xs-col-7-9 {
    grid-column: 7/9;
  }
  .grid .xs-col-7-10 {
    grid-column: 7/10;
  }
  .grid .xs-col-7-11 {
    grid-column: 7/11;
  }
  .grid .xs-col-7-12 {
    grid-column: 7/12;
  }
  .grid .xs-col-7-13 {
    grid-column: 7/13;
  }
  .grid .xs-col-8-1 {
    grid-column: 8/1;
  }
  .grid .xs-col-8-2 {
    grid-column: 8/2;
  }
  .grid .xs-col-8-3 {
    grid-column: 8/3;
  }
  .grid .xs-col-8-4 {
    grid-column: 8/4;
  }
  .grid .xs-col-8-5 {
    grid-column: 8/5;
  }
  .grid .xs-col-8-6 {
    grid-column: 8/6;
  }
  .grid .xs-col-8-7 {
    grid-column: 8/7;
  }
  .grid .xs-col-8-8 {
    grid-column: 8/8;
  }
  .grid .xs-col-8-9 {
    grid-column: 8/9;
  }
  .grid .xs-col-8-10 {
    grid-column: 8/10;
  }
  .grid .xs-col-8-11 {
    grid-column: 8/11;
  }
  .grid .xs-col-8-12 {
    grid-column: 8/12;
  }
  .grid .xs-col-8-13 {
    grid-column: 8/13;
  }
  .grid .xs-col-9-1 {
    grid-column: 9/1;
  }
  .grid .xs-col-9-2 {
    grid-column: 9/2;
  }
  .grid .xs-col-9-3 {
    grid-column: 9/3;
  }
  .grid .xs-col-9-4 {
    grid-column: 9/4;
  }
  .grid .xs-col-9-5 {
    grid-column: 9/5;
  }
  .grid .xs-col-9-6 {
    grid-column: 9/6;
  }
  .grid .xs-col-9-7 {
    grid-column: 9/7;
  }
  .grid .xs-col-9-8 {
    grid-column: 9/8;
  }
  .grid .xs-col-9-9 {
    grid-column: 9/9;
  }
  .grid .xs-col-9-10 {
    grid-column: 9/10;
  }
  .grid .xs-col-9-11 {
    grid-column: 9/11;
  }
  .grid .xs-col-9-12 {
    grid-column: 9/12;
  }
  .grid .xs-col-9-13 {
    grid-column: 9/13;
  }
  .grid .xs-col-10-1 {
    grid-column: 10/1;
  }
  .grid .xs-col-10-2 {
    grid-column: 10/2;
  }
  .grid .xs-col-10-3 {
    grid-column: 10/3;
  }
  .grid .xs-col-10-4 {
    grid-column: 10/4;
  }
  .grid .xs-col-10-5 {
    grid-column: 10/5;
  }
  .grid .xs-col-10-6 {
    grid-column: 10/6;
  }
  .grid .xs-col-10-7 {
    grid-column: 10/7;
  }
  .grid .xs-col-10-8 {
    grid-column: 10/8;
  }
  .grid .xs-col-10-9 {
    grid-column: 10/9;
  }
  .grid .xs-col-10-10 {
    grid-column: 10/10;
  }
  .grid .xs-col-10-11 {
    grid-column: 10/11;
  }
  .grid .xs-col-10-12 {
    grid-column: 10/12;
  }
  .grid .xs-col-10-13 {
    grid-column: 10/13;
  }
  .grid .xs-col-11-1 {
    grid-column: 11/1;
  }
  .grid .xs-col-11-2 {
    grid-column: 11/2;
  }
  .grid .xs-col-11-3 {
    grid-column: 11/3;
  }
  .grid .xs-col-11-4 {
    grid-column: 11/4;
  }
  .grid .xs-col-11-5 {
    grid-column: 11/5;
  }
  .grid .xs-col-11-6 {
    grid-column: 11/6;
  }
  .grid .xs-col-11-7 {
    grid-column: 11/7;
  }
  .grid .xs-col-11-8 {
    grid-column: 11/8;
  }
  .grid .xs-col-11-9 {
    grid-column: 11/9;
  }
  .grid .xs-col-11-10 {
    grid-column: 11/10;
  }
  .grid .xs-col-11-11 {
    grid-column: 11/11;
  }
  .grid .xs-col-11-12 {
    grid-column: 11/12;
  }
  .grid .xs-col-11-13 {
    grid-column: 11/13;
  }
  .grid .xs-col-12-1 {
    grid-column: 12/1;
  }
  .grid .xs-col-12-2 {
    grid-column: 12/2;
  }
  .grid .xs-col-12-3 {
    grid-column: 12/3;
  }
  .grid .xs-col-12-4 {
    grid-column: 12/4;
  }
  .grid .xs-col-12-5 {
    grid-column: 12/5;
  }
  .grid .xs-col-12-6 {
    grid-column: 12/6;
  }
  .grid .xs-col-12-7 {
    grid-column: 12/7;
  }
  .grid .xs-col-12-8 {
    grid-column: 12/8;
  }
  .grid .xs-col-12-9 {
    grid-column: 12/9;
  }
  .grid .xs-col-12-10 {
    grid-column: 12/10;
  }
  .grid .xs-col-12-11 {
    grid-column: 12/11;
  }
  .grid .xs-col-12-12 {
    grid-column: 12/12;
  }
  .grid .xs-col-12-13 {
    grid-column: 12/13;
  }
  .grid .xs-col-13-1 {
    grid-column: 13/1;
  }
  .grid .xs-col-13-2 {
    grid-column: 13/2;
  }
  .grid .xs-col-13-3 {
    grid-column: 13/3;
  }
  .grid .xs-col-13-4 {
    grid-column: 13/4;
  }
  .grid .xs-col-13-5 {
    grid-column: 13/5;
  }
  .grid .xs-col-13-6 {
    grid-column: 13/6;
  }
  .grid .xs-col-13-7 {
    grid-column: 13/7;
  }
  .grid .xs-col-13-8 {
    grid-column: 13/8;
  }
  .grid .xs-col-13-9 {
    grid-column: 13/9;
  }
  .grid .xs-col-13-10 {
    grid-column: 13/10;
  }
  .grid .xs-col-13-11 {
    grid-column: 13/11;
  }
  .grid .xs-col-13-12 {
    grid-column: 13/12;
  }
  .grid .xs-col-13-13 {
    grid-column: 13/13;
  }
}
@media (min-width: 576px) {
  .grid .sm-col-1-1 {
    grid-column: 1/1;
  }
  .grid .sm-col-1-2 {
    grid-column: 1/2;
  }
  .grid .sm-col-1-3 {
    grid-column: 1/3;
  }
  .grid .sm-col-1-4 {
    grid-column: 1/4;
  }
  .grid .sm-col-1-5 {
    grid-column: 1/5;
  }
  .grid .sm-col-1-6 {
    grid-column: 1/6;
  }
  .grid .sm-col-1-7 {
    grid-column: 1/7;
  }
  .grid .sm-col-1-8 {
    grid-column: 1/8;
  }
  .grid .sm-col-1-9 {
    grid-column: 1/9;
  }
  .grid .sm-col-1-10 {
    grid-column: 1/10;
  }
  .grid .sm-col-1-11 {
    grid-column: 1/11;
  }
  .grid .sm-col-1-12 {
    grid-column: 1/12;
  }
  .grid .sm-col-1-13 {
    grid-column: 1/13;
  }
  .grid .sm-col-2-1 {
    grid-column: 2/1;
  }
  .grid .sm-col-2-2 {
    grid-column: 2/2;
  }
  .grid .sm-col-2-3 {
    grid-column: 2/3;
  }
  .grid .sm-col-2-4 {
    grid-column: 2/4;
  }
  .grid .sm-col-2-5 {
    grid-column: 2/5;
  }
  .grid .sm-col-2-6 {
    grid-column: 2/6;
  }
  .grid .sm-col-2-7 {
    grid-column: 2/7;
  }
  .grid .sm-col-2-8 {
    grid-column: 2/8;
  }
  .grid .sm-col-2-9 {
    grid-column: 2/9;
  }
  .grid .sm-col-2-10 {
    grid-column: 2/10;
  }
  .grid .sm-col-2-11 {
    grid-column: 2/11;
  }
  .grid .sm-col-2-12 {
    grid-column: 2/12;
  }
  .grid .sm-col-2-13 {
    grid-column: 2/13;
  }
  .grid .sm-col-3-1 {
    grid-column: 3/1;
  }
  .grid .sm-col-3-2 {
    grid-column: 3/2;
  }
  .grid .sm-col-3-3 {
    grid-column: 3/3;
  }
  .grid .sm-col-3-4 {
    grid-column: 3/4;
  }
  .grid .sm-col-3-5 {
    grid-column: 3/5;
  }
  .grid .sm-col-3-6 {
    grid-column: 3/6;
  }
  .grid .sm-col-3-7 {
    grid-column: 3/7;
  }
  .grid .sm-col-3-8 {
    grid-column: 3/8;
  }
  .grid .sm-col-3-9 {
    grid-column: 3/9;
  }
  .grid .sm-col-3-10 {
    grid-column: 3/10;
  }
  .grid .sm-col-3-11 {
    grid-column: 3/11;
  }
  .grid .sm-col-3-12 {
    grid-column: 3/12;
  }
  .grid .sm-col-3-13 {
    grid-column: 3/13;
  }
  .grid .sm-col-4-1 {
    grid-column: 4/1;
  }
  .grid .sm-col-4-2 {
    grid-column: 4/2;
  }
  .grid .sm-col-4-3 {
    grid-column: 4/3;
  }
  .grid .sm-col-4-4 {
    grid-column: 4/4;
  }
  .grid .sm-col-4-5 {
    grid-column: 4/5;
  }
  .grid .sm-col-4-6 {
    grid-column: 4/6;
  }
  .grid .sm-col-4-7 {
    grid-column: 4/7;
  }
  .grid .sm-col-4-8 {
    grid-column: 4/8;
  }
  .grid .sm-col-4-9 {
    grid-column: 4/9;
  }
  .grid .sm-col-4-10 {
    grid-column: 4/10;
  }
  .grid .sm-col-4-11 {
    grid-column: 4/11;
  }
  .grid .sm-col-4-12 {
    grid-column: 4/12;
  }
  .grid .sm-col-4-13 {
    grid-column: 4/13;
  }
  .grid .sm-col-5-1 {
    grid-column: 5/1;
  }
  .grid .sm-col-5-2 {
    grid-column: 5/2;
  }
  .grid .sm-col-5-3 {
    grid-column: 5/3;
  }
  .grid .sm-col-5-4 {
    grid-column: 5/4;
  }
  .grid .sm-col-5-5 {
    grid-column: 5/5;
  }
  .grid .sm-col-5-6 {
    grid-column: 5/6;
  }
  .grid .sm-col-5-7 {
    grid-column: 5/7;
  }
  .grid .sm-col-5-8 {
    grid-column: 5/8;
  }
  .grid .sm-col-5-9 {
    grid-column: 5/9;
  }
  .grid .sm-col-5-10 {
    grid-column: 5/10;
  }
  .grid .sm-col-5-11 {
    grid-column: 5/11;
  }
  .grid .sm-col-5-12 {
    grid-column: 5/12;
  }
  .grid .sm-col-5-13 {
    grid-column: 5/13;
  }
  .grid .sm-col-6-1 {
    grid-column: 6/1;
  }
  .grid .sm-col-6-2 {
    grid-column: 6/2;
  }
  .grid .sm-col-6-3 {
    grid-column: 6/3;
  }
  .grid .sm-col-6-4 {
    grid-column: 6/4;
  }
  .grid .sm-col-6-5 {
    grid-column: 6/5;
  }
  .grid .sm-col-6-6 {
    grid-column: 6/6;
  }
  .grid .sm-col-6-7 {
    grid-column: 6/7;
  }
  .grid .sm-col-6-8 {
    grid-column: 6/8;
  }
  .grid .sm-col-6-9 {
    grid-column: 6/9;
  }
  .grid .sm-col-6-10 {
    grid-column: 6/10;
  }
  .grid .sm-col-6-11 {
    grid-column: 6/11;
  }
  .grid .sm-col-6-12 {
    grid-column: 6/12;
  }
  .grid .sm-col-6-13 {
    grid-column: 6/13;
  }
  .grid .sm-col-7-1 {
    grid-column: 7/1;
  }
  .grid .sm-col-7-2 {
    grid-column: 7/2;
  }
  .grid .sm-col-7-3 {
    grid-column: 7/3;
  }
  .grid .sm-col-7-4 {
    grid-column: 7/4;
  }
  .grid .sm-col-7-5 {
    grid-column: 7/5;
  }
  .grid .sm-col-7-6 {
    grid-column: 7/6;
  }
  .grid .sm-col-7-7 {
    grid-column: 7/7;
  }
  .grid .sm-col-7-8 {
    grid-column: 7/8;
  }
  .grid .sm-col-7-9 {
    grid-column: 7/9;
  }
  .grid .sm-col-7-10 {
    grid-column: 7/10;
  }
  .grid .sm-col-7-11 {
    grid-column: 7/11;
  }
  .grid .sm-col-7-12 {
    grid-column: 7/12;
  }
  .grid .sm-col-7-13 {
    grid-column: 7/13;
  }
  .grid .sm-col-8-1 {
    grid-column: 8/1;
  }
  .grid .sm-col-8-2 {
    grid-column: 8/2;
  }
  .grid .sm-col-8-3 {
    grid-column: 8/3;
  }
  .grid .sm-col-8-4 {
    grid-column: 8/4;
  }
  .grid .sm-col-8-5 {
    grid-column: 8/5;
  }
  .grid .sm-col-8-6 {
    grid-column: 8/6;
  }
  .grid .sm-col-8-7 {
    grid-column: 8/7;
  }
  .grid .sm-col-8-8 {
    grid-column: 8/8;
  }
  .grid .sm-col-8-9 {
    grid-column: 8/9;
  }
  .grid .sm-col-8-10 {
    grid-column: 8/10;
  }
  .grid .sm-col-8-11 {
    grid-column: 8/11;
  }
  .grid .sm-col-8-12 {
    grid-column: 8/12;
  }
  .grid .sm-col-8-13 {
    grid-column: 8/13;
  }
  .grid .sm-col-9-1 {
    grid-column: 9/1;
  }
  .grid .sm-col-9-2 {
    grid-column: 9/2;
  }
  .grid .sm-col-9-3 {
    grid-column: 9/3;
  }
  .grid .sm-col-9-4 {
    grid-column: 9/4;
  }
  .grid .sm-col-9-5 {
    grid-column: 9/5;
  }
  .grid .sm-col-9-6 {
    grid-column: 9/6;
  }
  .grid .sm-col-9-7 {
    grid-column: 9/7;
  }
  .grid .sm-col-9-8 {
    grid-column: 9/8;
  }
  .grid .sm-col-9-9 {
    grid-column: 9/9;
  }
  .grid .sm-col-9-10 {
    grid-column: 9/10;
  }
  .grid .sm-col-9-11 {
    grid-column: 9/11;
  }
  .grid .sm-col-9-12 {
    grid-column: 9/12;
  }
  .grid .sm-col-9-13 {
    grid-column: 9/13;
  }
  .grid .sm-col-10-1 {
    grid-column: 10/1;
  }
  .grid .sm-col-10-2 {
    grid-column: 10/2;
  }
  .grid .sm-col-10-3 {
    grid-column: 10/3;
  }
  .grid .sm-col-10-4 {
    grid-column: 10/4;
  }
  .grid .sm-col-10-5 {
    grid-column: 10/5;
  }
  .grid .sm-col-10-6 {
    grid-column: 10/6;
  }
  .grid .sm-col-10-7 {
    grid-column: 10/7;
  }
  .grid .sm-col-10-8 {
    grid-column: 10/8;
  }
  .grid .sm-col-10-9 {
    grid-column: 10/9;
  }
  .grid .sm-col-10-10 {
    grid-column: 10/10;
  }
  .grid .sm-col-10-11 {
    grid-column: 10/11;
  }
  .grid .sm-col-10-12 {
    grid-column: 10/12;
  }
  .grid .sm-col-10-13 {
    grid-column: 10/13;
  }
  .grid .sm-col-11-1 {
    grid-column: 11/1;
  }
  .grid .sm-col-11-2 {
    grid-column: 11/2;
  }
  .grid .sm-col-11-3 {
    grid-column: 11/3;
  }
  .grid .sm-col-11-4 {
    grid-column: 11/4;
  }
  .grid .sm-col-11-5 {
    grid-column: 11/5;
  }
  .grid .sm-col-11-6 {
    grid-column: 11/6;
  }
  .grid .sm-col-11-7 {
    grid-column: 11/7;
  }
  .grid .sm-col-11-8 {
    grid-column: 11/8;
  }
  .grid .sm-col-11-9 {
    grid-column: 11/9;
  }
  .grid .sm-col-11-10 {
    grid-column: 11/10;
  }
  .grid .sm-col-11-11 {
    grid-column: 11/11;
  }
  .grid .sm-col-11-12 {
    grid-column: 11/12;
  }
  .grid .sm-col-11-13 {
    grid-column: 11/13;
  }
  .grid .sm-col-12-1 {
    grid-column: 12/1;
  }
  .grid .sm-col-12-2 {
    grid-column: 12/2;
  }
  .grid .sm-col-12-3 {
    grid-column: 12/3;
  }
  .grid .sm-col-12-4 {
    grid-column: 12/4;
  }
  .grid .sm-col-12-5 {
    grid-column: 12/5;
  }
  .grid .sm-col-12-6 {
    grid-column: 12/6;
  }
  .grid .sm-col-12-7 {
    grid-column: 12/7;
  }
  .grid .sm-col-12-8 {
    grid-column: 12/8;
  }
  .grid .sm-col-12-9 {
    grid-column: 12/9;
  }
  .grid .sm-col-12-10 {
    grid-column: 12/10;
  }
  .grid .sm-col-12-11 {
    grid-column: 12/11;
  }
  .grid .sm-col-12-12 {
    grid-column: 12/12;
  }
  .grid .sm-col-12-13 {
    grid-column: 12/13;
  }
  .grid .sm-col-13-1 {
    grid-column: 13/1;
  }
  .grid .sm-col-13-2 {
    grid-column: 13/2;
  }
  .grid .sm-col-13-3 {
    grid-column: 13/3;
  }
  .grid .sm-col-13-4 {
    grid-column: 13/4;
  }
  .grid .sm-col-13-5 {
    grid-column: 13/5;
  }
  .grid .sm-col-13-6 {
    grid-column: 13/6;
  }
  .grid .sm-col-13-7 {
    grid-column: 13/7;
  }
  .grid .sm-col-13-8 {
    grid-column: 13/8;
  }
  .grid .sm-col-13-9 {
    grid-column: 13/9;
  }
  .grid .sm-col-13-10 {
    grid-column: 13/10;
  }
  .grid .sm-col-13-11 {
    grid-column: 13/11;
  }
  .grid .sm-col-13-12 {
    grid-column: 13/12;
  }
  .grid .sm-col-13-13 {
    grid-column: 13/13;
  }
}
@media (min-width: 768px) {
  .grid .md-col-1-1 {
    grid-column: 1/1;
  }
  .grid .md-col-1-2 {
    grid-column: 1/2;
  }
  .grid .md-col-1-3 {
    grid-column: 1/3;
  }
  .grid .md-col-1-4 {
    grid-column: 1/4;
  }
  .grid .md-col-1-5 {
    grid-column: 1/5;
  }
  .grid .md-col-1-6 {
    grid-column: 1/6;
  }
  .grid .md-col-1-7 {
    grid-column: 1/7;
  }
  .grid .md-col-1-8 {
    grid-column: 1/8;
  }
  .grid .md-col-1-9 {
    grid-column: 1/9;
  }
  .grid .md-col-1-10 {
    grid-column: 1/10;
  }
  .grid .md-col-1-11 {
    grid-column: 1/11;
  }
  .grid .md-col-1-12 {
    grid-column: 1/12;
  }
  .grid .md-col-1-13 {
    grid-column: 1/13;
  }
  .grid .md-col-2-1 {
    grid-column: 2/1;
  }
  .grid .md-col-2-2 {
    grid-column: 2/2;
  }
  .grid .md-col-2-3 {
    grid-column: 2/3;
  }
  .grid .md-col-2-4 {
    grid-column: 2/4;
  }
  .grid .md-col-2-5 {
    grid-column: 2/5;
  }
  .grid .md-col-2-6 {
    grid-column: 2/6;
  }
  .grid .md-col-2-7 {
    grid-column: 2/7;
  }
  .grid .md-col-2-8 {
    grid-column: 2/8;
  }
  .grid .md-col-2-9 {
    grid-column: 2/9;
  }
  .grid .md-col-2-10 {
    grid-column: 2/10;
  }
  .grid .md-col-2-11 {
    grid-column: 2/11;
  }
  .grid .md-col-2-12 {
    grid-column: 2/12;
  }
  .grid .md-col-2-13 {
    grid-column: 2/13;
  }
  .grid .md-col-3-1 {
    grid-column: 3/1;
  }
  .grid .md-col-3-2 {
    grid-column: 3/2;
  }
  .grid .md-col-3-3 {
    grid-column: 3/3;
  }
  .grid .md-col-3-4 {
    grid-column: 3/4;
  }
  .grid .md-col-3-5 {
    grid-column: 3/5;
  }
  .grid .md-col-3-6 {
    grid-column: 3/6;
  }
  .grid .md-col-3-7 {
    grid-column: 3/7;
  }
  .grid .md-col-3-8 {
    grid-column: 3/8;
  }
  .grid .md-col-3-9 {
    grid-column: 3/9;
  }
  .grid .md-col-3-10 {
    grid-column: 3/10;
  }
  .grid .md-col-3-11 {
    grid-column: 3/11;
  }
  .grid .md-col-3-12 {
    grid-column: 3/12;
  }
  .grid .md-col-3-13 {
    grid-column: 3/13;
  }
  .grid .md-col-4-1 {
    grid-column: 4/1;
  }
  .grid .md-col-4-2 {
    grid-column: 4/2;
  }
  .grid .md-col-4-3 {
    grid-column: 4/3;
  }
  .grid .md-col-4-4 {
    grid-column: 4/4;
  }
  .grid .md-col-4-5 {
    grid-column: 4/5;
  }
  .grid .md-col-4-6 {
    grid-column: 4/6;
  }
  .grid .md-col-4-7 {
    grid-column: 4/7;
  }
  .grid .md-col-4-8 {
    grid-column: 4/8;
  }
  .grid .md-col-4-9 {
    grid-column: 4/9;
  }
  .grid .md-col-4-10 {
    grid-column: 4/10;
  }
  .grid .md-col-4-11 {
    grid-column: 4/11;
  }
  .grid .md-col-4-12 {
    grid-column: 4/12;
  }
  .grid .md-col-4-13 {
    grid-column: 4/13;
  }
  .grid .md-col-5-1 {
    grid-column: 5/1;
  }
  .grid .md-col-5-2 {
    grid-column: 5/2;
  }
  .grid .md-col-5-3 {
    grid-column: 5/3;
  }
  .grid .md-col-5-4 {
    grid-column: 5/4;
  }
  .grid .md-col-5-5 {
    grid-column: 5/5;
  }
  .grid .md-col-5-6 {
    grid-column: 5/6;
  }
  .grid .md-col-5-7 {
    grid-column: 5/7;
  }
  .grid .md-col-5-8 {
    grid-column: 5/8;
  }
  .grid .md-col-5-9 {
    grid-column: 5/9;
  }
  .grid .md-col-5-10 {
    grid-column: 5/10;
  }
  .grid .md-col-5-11 {
    grid-column: 5/11;
  }
  .grid .md-col-5-12 {
    grid-column: 5/12;
  }
  .grid .md-col-5-13 {
    grid-column: 5/13;
  }
  .grid .md-col-6-1 {
    grid-column: 6/1;
  }
  .grid .md-col-6-2 {
    grid-column: 6/2;
  }
  .grid .md-col-6-3 {
    grid-column: 6/3;
  }
  .grid .md-col-6-4 {
    grid-column: 6/4;
  }
  .grid .md-col-6-5 {
    grid-column: 6/5;
  }
  .grid .md-col-6-6 {
    grid-column: 6/6;
  }
  .grid .md-col-6-7 {
    grid-column: 6/7;
  }
  .grid .md-col-6-8 {
    grid-column: 6/8;
  }
  .grid .md-col-6-9 {
    grid-column: 6/9;
  }
  .grid .md-col-6-10 {
    grid-column: 6/10;
  }
  .grid .md-col-6-11 {
    grid-column: 6/11;
  }
  .grid .md-col-6-12 {
    grid-column: 6/12;
  }
  .grid .md-col-6-13 {
    grid-column: 6/13;
  }
  .grid .md-col-7-1 {
    grid-column: 7/1;
  }
  .grid .md-col-7-2 {
    grid-column: 7/2;
  }
  .grid .md-col-7-3 {
    grid-column: 7/3;
  }
  .grid .md-col-7-4 {
    grid-column: 7/4;
  }
  .grid .md-col-7-5 {
    grid-column: 7/5;
  }
  .grid .md-col-7-6 {
    grid-column: 7/6;
  }
  .grid .md-col-7-7 {
    grid-column: 7/7;
  }
  .grid .md-col-7-8 {
    grid-column: 7/8;
  }
  .grid .md-col-7-9 {
    grid-column: 7/9;
  }
  .grid .md-col-7-10 {
    grid-column: 7/10;
  }
  .grid .md-col-7-11 {
    grid-column: 7/11;
  }
  .grid .md-col-7-12 {
    grid-column: 7/12;
  }
  .grid .md-col-7-13 {
    grid-column: 7/13;
  }
  .grid .md-col-8-1 {
    grid-column: 8/1;
  }
  .grid .md-col-8-2 {
    grid-column: 8/2;
  }
  .grid .md-col-8-3 {
    grid-column: 8/3;
  }
  .grid .md-col-8-4 {
    grid-column: 8/4;
  }
  .grid .md-col-8-5 {
    grid-column: 8/5;
  }
  .grid .md-col-8-6 {
    grid-column: 8/6;
  }
  .grid .md-col-8-7 {
    grid-column: 8/7;
  }
  .grid .md-col-8-8 {
    grid-column: 8/8;
  }
  .grid .md-col-8-9 {
    grid-column: 8/9;
  }
  .grid .md-col-8-10 {
    grid-column: 8/10;
  }
  .grid .md-col-8-11 {
    grid-column: 8/11;
  }
  .grid .md-col-8-12 {
    grid-column: 8/12;
  }
  .grid .md-col-8-13 {
    grid-column: 8/13;
  }
  .grid .md-col-9-1 {
    grid-column: 9/1;
  }
  .grid .md-col-9-2 {
    grid-column: 9/2;
  }
  .grid .md-col-9-3 {
    grid-column: 9/3;
  }
  .grid .md-col-9-4 {
    grid-column: 9/4;
  }
  .grid .md-col-9-5 {
    grid-column: 9/5;
  }
  .grid .md-col-9-6 {
    grid-column: 9/6;
  }
  .grid .md-col-9-7 {
    grid-column: 9/7;
  }
  .grid .md-col-9-8 {
    grid-column: 9/8;
  }
  .grid .md-col-9-9 {
    grid-column: 9/9;
  }
  .grid .md-col-9-10 {
    grid-column: 9/10;
  }
  .grid .md-col-9-11 {
    grid-column: 9/11;
  }
  .grid .md-col-9-12 {
    grid-column: 9/12;
  }
  .grid .md-col-9-13 {
    grid-column: 9/13;
  }
  .grid .md-col-10-1 {
    grid-column: 10/1;
  }
  .grid .md-col-10-2 {
    grid-column: 10/2;
  }
  .grid .md-col-10-3 {
    grid-column: 10/3;
  }
  .grid .md-col-10-4 {
    grid-column: 10/4;
  }
  .grid .md-col-10-5 {
    grid-column: 10/5;
  }
  .grid .md-col-10-6 {
    grid-column: 10/6;
  }
  .grid .md-col-10-7 {
    grid-column: 10/7;
  }
  .grid .md-col-10-8 {
    grid-column: 10/8;
  }
  .grid .md-col-10-9 {
    grid-column: 10/9;
  }
  .grid .md-col-10-10 {
    grid-column: 10/10;
  }
  .grid .md-col-10-11 {
    grid-column: 10/11;
  }
  .grid .md-col-10-12 {
    grid-column: 10/12;
  }
  .grid .md-col-10-13 {
    grid-column: 10/13;
  }
  .grid .md-col-11-1 {
    grid-column: 11/1;
  }
  .grid .md-col-11-2 {
    grid-column: 11/2;
  }
  .grid .md-col-11-3 {
    grid-column: 11/3;
  }
  .grid .md-col-11-4 {
    grid-column: 11/4;
  }
  .grid .md-col-11-5 {
    grid-column: 11/5;
  }
  .grid .md-col-11-6 {
    grid-column: 11/6;
  }
  .grid .md-col-11-7 {
    grid-column: 11/7;
  }
  .grid .md-col-11-8 {
    grid-column: 11/8;
  }
  .grid .md-col-11-9 {
    grid-column: 11/9;
  }
  .grid .md-col-11-10 {
    grid-column: 11/10;
  }
  .grid .md-col-11-11 {
    grid-column: 11/11;
  }
  .grid .md-col-11-12 {
    grid-column: 11/12;
  }
  .grid .md-col-11-13 {
    grid-column: 11/13;
  }
  .grid .md-col-12-1 {
    grid-column: 12/1;
  }
  .grid .md-col-12-2 {
    grid-column: 12/2;
  }
  .grid .md-col-12-3 {
    grid-column: 12/3;
  }
  .grid .md-col-12-4 {
    grid-column: 12/4;
  }
  .grid .md-col-12-5 {
    grid-column: 12/5;
  }
  .grid .md-col-12-6 {
    grid-column: 12/6;
  }
  .grid .md-col-12-7 {
    grid-column: 12/7;
  }
  .grid .md-col-12-8 {
    grid-column: 12/8;
  }
  .grid .md-col-12-9 {
    grid-column: 12/9;
  }
  .grid .md-col-12-10 {
    grid-column: 12/10;
  }
  .grid .md-col-12-11 {
    grid-column: 12/11;
  }
  .grid .md-col-12-12 {
    grid-column: 12/12;
  }
  .grid .md-col-12-13 {
    grid-column: 12/13;
  }
  .grid .md-col-13-1 {
    grid-column: 13/1;
  }
  .grid .md-col-13-2 {
    grid-column: 13/2;
  }
  .grid .md-col-13-3 {
    grid-column: 13/3;
  }
  .grid .md-col-13-4 {
    grid-column: 13/4;
  }
  .grid .md-col-13-5 {
    grid-column: 13/5;
  }
  .grid .md-col-13-6 {
    grid-column: 13/6;
  }
  .grid .md-col-13-7 {
    grid-column: 13/7;
  }
  .grid .md-col-13-8 {
    grid-column: 13/8;
  }
  .grid .md-col-13-9 {
    grid-column: 13/9;
  }
  .grid .md-col-13-10 {
    grid-column: 13/10;
  }
  .grid .md-col-13-11 {
    grid-column: 13/11;
  }
  .grid .md-col-13-12 {
    grid-column: 13/12;
  }
  .grid .md-col-13-13 {
    grid-column: 13/13;
  }
}
@media (min-width: 992px) {
  .grid .lg-col-1-1 {
    grid-column: 1/1;
  }
  .grid .lg-col-1-2 {
    grid-column: 1/2;
  }
  .grid .lg-col-1-3 {
    grid-column: 1/3;
  }
  .grid .lg-col-1-4 {
    grid-column: 1/4;
  }
  .grid .lg-col-1-5 {
    grid-column: 1/5;
  }
  .grid .lg-col-1-6 {
    grid-column: 1/6;
  }
  .grid .lg-col-1-7 {
    grid-column: 1/7;
  }
  .grid .lg-col-1-8 {
    grid-column: 1/8;
  }
  .grid .lg-col-1-9 {
    grid-column: 1/9;
  }
  .grid .lg-col-1-10 {
    grid-column: 1/10;
  }
  .grid .lg-col-1-11 {
    grid-column: 1/11;
  }
  .grid .lg-col-1-12 {
    grid-column: 1/12;
  }
  .grid .lg-col-1-13 {
    grid-column: 1/13;
  }
  .grid .lg-col-2-1 {
    grid-column: 2/1;
  }
  .grid .lg-col-2-2 {
    grid-column: 2/2;
  }
  .grid .lg-col-2-3 {
    grid-column: 2/3;
  }
  .grid .lg-col-2-4 {
    grid-column: 2/4;
  }
  .grid .lg-col-2-5 {
    grid-column: 2/5;
  }
  .grid .lg-col-2-6 {
    grid-column: 2/6;
  }
  .grid .lg-col-2-7 {
    grid-column: 2/7;
  }
  .grid .lg-col-2-8 {
    grid-column: 2/8;
  }
  .grid .lg-col-2-9 {
    grid-column: 2/9;
  }
  .grid .lg-col-2-10 {
    grid-column: 2/10;
  }
  .grid .lg-col-2-11 {
    grid-column: 2/11;
  }
  .grid .lg-col-2-12 {
    grid-column: 2/12;
  }
  .grid .lg-col-2-13 {
    grid-column: 2/13;
  }
  .grid .lg-col-3-1 {
    grid-column: 3/1;
  }
  .grid .lg-col-3-2 {
    grid-column: 3/2;
  }
  .grid .lg-col-3-3 {
    grid-column: 3/3;
  }
  .grid .lg-col-3-4 {
    grid-column: 3/4;
  }
  .grid .lg-col-3-5 {
    grid-column: 3/5;
  }
  .grid .lg-col-3-6 {
    grid-column: 3/6;
  }
  .grid .lg-col-3-7 {
    grid-column: 3/7;
  }
  .grid .lg-col-3-8 {
    grid-column: 3/8;
  }
  .grid .lg-col-3-9 {
    grid-column: 3/9;
  }
  .grid .lg-col-3-10 {
    grid-column: 3/10;
  }
  .grid .lg-col-3-11 {
    grid-column: 3/11;
  }
  .grid .lg-col-3-12 {
    grid-column: 3/12;
  }
  .grid .lg-col-3-13 {
    grid-column: 3/13;
  }
  .grid .lg-col-4-1 {
    grid-column: 4/1;
  }
  .grid .lg-col-4-2 {
    grid-column: 4/2;
  }
  .grid .lg-col-4-3 {
    grid-column: 4/3;
  }
  .grid .lg-col-4-4 {
    grid-column: 4/4;
  }
  .grid .lg-col-4-5 {
    grid-column: 4/5;
  }
  .grid .lg-col-4-6 {
    grid-column: 4/6;
  }
  .grid .lg-col-4-7 {
    grid-column: 4/7;
  }
  .grid .lg-col-4-8 {
    grid-column: 4/8;
  }
  .grid .lg-col-4-9 {
    grid-column: 4/9;
  }
  .grid .lg-col-4-10 {
    grid-column: 4/10;
  }
  .grid .lg-col-4-11 {
    grid-column: 4/11;
  }
  .grid .lg-col-4-12 {
    grid-column: 4/12;
  }
  .grid .lg-col-4-13 {
    grid-column: 4/13;
  }
  .grid .lg-col-5-1 {
    grid-column: 5/1;
  }
  .grid .lg-col-5-2 {
    grid-column: 5/2;
  }
  .grid .lg-col-5-3 {
    grid-column: 5/3;
  }
  .grid .lg-col-5-4 {
    grid-column: 5/4;
  }
  .grid .lg-col-5-5 {
    grid-column: 5/5;
  }
  .grid .lg-col-5-6 {
    grid-column: 5/6;
  }
  .grid .lg-col-5-7 {
    grid-column: 5/7;
  }
  .grid .lg-col-5-8 {
    grid-column: 5/8;
  }
  .grid .lg-col-5-9 {
    grid-column: 5/9;
  }
  .grid .lg-col-5-10 {
    grid-column: 5/10;
  }
  .grid .lg-col-5-11 {
    grid-column: 5/11;
  }
  .grid .lg-col-5-12 {
    grid-column: 5/12;
  }
  .grid .lg-col-5-13 {
    grid-column: 5/13;
  }
  .grid .lg-col-6-1 {
    grid-column: 6/1;
  }
  .grid .lg-col-6-2 {
    grid-column: 6/2;
  }
  .grid .lg-col-6-3 {
    grid-column: 6/3;
  }
  .grid .lg-col-6-4 {
    grid-column: 6/4;
  }
  .grid .lg-col-6-5 {
    grid-column: 6/5;
  }
  .grid .lg-col-6-6 {
    grid-column: 6/6;
  }
  .grid .lg-col-6-7 {
    grid-column: 6/7;
  }
  .grid .lg-col-6-8 {
    grid-column: 6/8;
  }
  .grid .lg-col-6-9 {
    grid-column: 6/9;
  }
  .grid .lg-col-6-10 {
    grid-column: 6/10;
  }
  .grid .lg-col-6-11 {
    grid-column: 6/11;
  }
  .grid .lg-col-6-12 {
    grid-column: 6/12;
  }
  .grid .lg-col-6-13 {
    grid-column: 6/13;
  }
  .grid .lg-col-7-1 {
    grid-column: 7/1;
  }
  .grid .lg-col-7-2 {
    grid-column: 7/2;
  }
  .grid .lg-col-7-3 {
    grid-column: 7/3;
  }
  .grid .lg-col-7-4 {
    grid-column: 7/4;
  }
  .grid .lg-col-7-5 {
    grid-column: 7/5;
  }
  .grid .lg-col-7-6 {
    grid-column: 7/6;
  }
  .grid .lg-col-7-7 {
    grid-column: 7/7;
  }
  .grid .lg-col-7-8 {
    grid-column: 7/8;
  }
  .grid .lg-col-7-9 {
    grid-column: 7/9;
  }
  .grid .lg-col-7-10 {
    grid-column: 7/10;
  }
  .grid .lg-col-7-11 {
    grid-column: 7/11;
  }
  .grid .lg-col-7-12 {
    grid-column: 7/12;
  }
  .grid .lg-col-7-13 {
    grid-column: 7/13;
  }
  .grid .lg-col-8-1 {
    grid-column: 8/1;
  }
  .grid .lg-col-8-2 {
    grid-column: 8/2;
  }
  .grid .lg-col-8-3 {
    grid-column: 8/3;
  }
  .grid .lg-col-8-4 {
    grid-column: 8/4;
  }
  .grid .lg-col-8-5 {
    grid-column: 8/5;
  }
  .grid .lg-col-8-6 {
    grid-column: 8/6;
  }
  .grid .lg-col-8-7 {
    grid-column: 8/7;
  }
  .grid .lg-col-8-8 {
    grid-column: 8/8;
  }
  .grid .lg-col-8-9 {
    grid-column: 8/9;
  }
  .grid .lg-col-8-10 {
    grid-column: 8/10;
  }
  .grid .lg-col-8-11 {
    grid-column: 8/11;
  }
  .grid .lg-col-8-12 {
    grid-column: 8/12;
  }
  .grid .lg-col-8-13 {
    grid-column: 8/13;
  }
  .grid .lg-col-9-1 {
    grid-column: 9/1;
  }
  .grid .lg-col-9-2 {
    grid-column: 9/2;
  }
  .grid .lg-col-9-3 {
    grid-column: 9/3;
  }
  .grid .lg-col-9-4 {
    grid-column: 9/4;
  }
  .grid .lg-col-9-5 {
    grid-column: 9/5;
  }
  .grid .lg-col-9-6 {
    grid-column: 9/6;
  }
  .grid .lg-col-9-7 {
    grid-column: 9/7;
  }
  .grid .lg-col-9-8 {
    grid-column: 9/8;
  }
  .grid .lg-col-9-9 {
    grid-column: 9/9;
  }
  .grid .lg-col-9-10 {
    grid-column: 9/10;
  }
  .grid .lg-col-9-11 {
    grid-column: 9/11;
  }
  .grid .lg-col-9-12 {
    grid-column: 9/12;
  }
  .grid .lg-col-9-13 {
    grid-column: 9/13;
  }
  .grid .lg-col-10-1 {
    grid-column: 10/1;
  }
  .grid .lg-col-10-2 {
    grid-column: 10/2;
  }
  .grid .lg-col-10-3 {
    grid-column: 10/3;
  }
  .grid .lg-col-10-4 {
    grid-column: 10/4;
  }
  .grid .lg-col-10-5 {
    grid-column: 10/5;
  }
  .grid .lg-col-10-6 {
    grid-column: 10/6;
  }
  .grid .lg-col-10-7 {
    grid-column: 10/7;
  }
  .grid .lg-col-10-8 {
    grid-column: 10/8;
  }
  .grid .lg-col-10-9 {
    grid-column: 10/9;
  }
  .grid .lg-col-10-10 {
    grid-column: 10/10;
  }
  .grid .lg-col-10-11 {
    grid-column: 10/11;
  }
  .grid .lg-col-10-12 {
    grid-column: 10/12;
  }
  .grid .lg-col-10-13 {
    grid-column: 10/13;
  }
  .grid .lg-col-11-1 {
    grid-column: 11/1;
  }
  .grid .lg-col-11-2 {
    grid-column: 11/2;
  }
  .grid .lg-col-11-3 {
    grid-column: 11/3;
  }
  .grid .lg-col-11-4 {
    grid-column: 11/4;
  }
  .grid .lg-col-11-5 {
    grid-column: 11/5;
  }
  .grid .lg-col-11-6 {
    grid-column: 11/6;
  }
  .grid .lg-col-11-7 {
    grid-column: 11/7;
  }
  .grid .lg-col-11-8 {
    grid-column: 11/8;
  }
  .grid .lg-col-11-9 {
    grid-column: 11/9;
  }
  .grid .lg-col-11-10 {
    grid-column: 11/10;
  }
  .grid .lg-col-11-11 {
    grid-column: 11/11;
  }
  .grid .lg-col-11-12 {
    grid-column: 11/12;
  }
  .grid .lg-col-11-13 {
    grid-column: 11/13;
  }
  .grid .lg-col-12-1 {
    grid-column: 12/1;
  }
  .grid .lg-col-12-2 {
    grid-column: 12/2;
  }
  .grid .lg-col-12-3 {
    grid-column: 12/3;
  }
  .grid .lg-col-12-4 {
    grid-column: 12/4;
  }
  .grid .lg-col-12-5 {
    grid-column: 12/5;
  }
  .grid .lg-col-12-6 {
    grid-column: 12/6;
  }
  .grid .lg-col-12-7 {
    grid-column: 12/7;
  }
  .grid .lg-col-12-8 {
    grid-column: 12/8;
  }
  .grid .lg-col-12-9 {
    grid-column: 12/9;
  }
  .grid .lg-col-12-10 {
    grid-column: 12/10;
  }
  .grid .lg-col-12-11 {
    grid-column: 12/11;
  }
  .grid .lg-col-12-12 {
    grid-column: 12/12;
  }
  .grid .lg-col-12-13 {
    grid-column: 12/13;
  }
  .grid .lg-col-13-1 {
    grid-column: 13/1;
  }
  .grid .lg-col-13-2 {
    grid-column: 13/2;
  }
  .grid .lg-col-13-3 {
    grid-column: 13/3;
  }
  .grid .lg-col-13-4 {
    grid-column: 13/4;
  }
  .grid .lg-col-13-5 {
    grid-column: 13/5;
  }
  .grid .lg-col-13-6 {
    grid-column: 13/6;
  }
  .grid .lg-col-13-7 {
    grid-column: 13/7;
  }
  .grid .lg-col-13-8 {
    grid-column: 13/8;
  }
  .grid .lg-col-13-9 {
    grid-column: 13/9;
  }
  .grid .lg-col-13-10 {
    grid-column: 13/10;
  }
  .grid .lg-col-13-11 {
    grid-column: 13/11;
  }
  .grid .lg-col-13-12 {
    grid-column: 13/12;
  }
  .grid .lg-col-13-13 {
    grid-column: 13/13;
  }
}
@media (min-width: 1200px) {
  .grid .xl-col-1-1 {
    grid-column: 1/1;
  }
  .grid .xl-col-1-2 {
    grid-column: 1/2;
  }
  .grid .xl-col-1-3 {
    grid-column: 1/3;
  }
  .grid .xl-col-1-4 {
    grid-column: 1/4;
  }
  .grid .xl-col-1-5 {
    grid-column: 1/5;
  }
  .grid .xl-col-1-6 {
    grid-column: 1/6;
  }
  .grid .xl-col-1-7 {
    grid-column: 1/7;
  }
  .grid .xl-col-1-8 {
    grid-column: 1/8;
  }
  .grid .xl-col-1-9 {
    grid-column: 1/9;
  }
  .grid .xl-col-1-10 {
    grid-column: 1/10;
  }
  .grid .xl-col-1-11 {
    grid-column: 1/11;
  }
  .grid .xl-col-1-12 {
    grid-column: 1/12;
  }
  .grid .xl-col-1-13 {
    grid-column: 1/13;
  }
  .grid .xl-col-2-1 {
    grid-column: 2/1;
  }
  .grid .xl-col-2-2 {
    grid-column: 2/2;
  }
  .grid .xl-col-2-3 {
    grid-column: 2/3;
  }
  .grid .xl-col-2-4 {
    grid-column: 2/4;
  }
  .grid .xl-col-2-5 {
    grid-column: 2/5;
  }
  .grid .xl-col-2-6 {
    grid-column: 2/6;
  }
  .grid .xl-col-2-7 {
    grid-column: 2/7;
  }
  .grid .xl-col-2-8 {
    grid-column: 2/8;
  }
  .grid .xl-col-2-9 {
    grid-column: 2/9;
  }
  .grid .xl-col-2-10 {
    grid-column: 2/10;
  }
  .grid .xl-col-2-11 {
    grid-column: 2/11;
  }
  .grid .xl-col-2-12 {
    grid-column: 2/12;
  }
  .grid .xl-col-2-13 {
    grid-column: 2/13;
  }
  .grid .xl-col-3-1 {
    grid-column: 3/1;
  }
  .grid .xl-col-3-2 {
    grid-column: 3/2;
  }
  .grid .xl-col-3-3 {
    grid-column: 3/3;
  }
  .grid .xl-col-3-4 {
    grid-column: 3/4;
  }
  .grid .xl-col-3-5 {
    grid-column: 3/5;
  }
  .grid .xl-col-3-6 {
    grid-column: 3/6;
  }
  .grid .xl-col-3-7 {
    grid-column: 3/7;
  }
  .grid .xl-col-3-8 {
    grid-column: 3/8;
  }
  .grid .xl-col-3-9 {
    grid-column: 3/9;
  }
  .grid .xl-col-3-10 {
    grid-column: 3/10;
  }
  .grid .xl-col-3-11 {
    grid-column: 3/11;
  }
  .grid .xl-col-3-12 {
    grid-column: 3/12;
  }
  .grid .xl-col-3-13 {
    grid-column: 3/13;
  }
  .grid .xl-col-4-1 {
    grid-column: 4/1;
  }
  .grid .xl-col-4-2 {
    grid-column: 4/2;
  }
  .grid .xl-col-4-3 {
    grid-column: 4/3;
  }
  .grid .xl-col-4-4 {
    grid-column: 4/4;
  }
  .grid .xl-col-4-5 {
    grid-column: 4/5;
  }
  .grid .xl-col-4-6 {
    grid-column: 4/6;
  }
  .grid .xl-col-4-7 {
    grid-column: 4/7;
  }
  .grid .xl-col-4-8 {
    grid-column: 4/8;
  }
  .grid .xl-col-4-9 {
    grid-column: 4/9;
  }
  .grid .xl-col-4-10 {
    grid-column: 4/10;
  }
  .grid .xl-col-4-11 {
    grid-column: 4/11;
  }
  .grid .xl-col-4-12 {
    grid-column: 4/12;
  }
  .grid .xl-col-4-13 {
    grid-column: 4/13;
  }
  .grid .xl-col-5-1 {
    grid-column: 5/1;
  }
  .grid .xl-col-5-2 {
    grid-column: 5/2;
  }
  .grid .xl-col-5-3 {
    grid-column: 5/3;
  }
  .grid .xl-col-5-4 {
    grid-column: 5/4;
  }
  .grid .xl-col-5-5 {
    grid-column: 5/5;
  }
  .grid .xl-col-5-6 {
    grid-column: 5/6;
  }
  .grid .xl-col-5-7 {
    grid-column: 5/7;
  }
  .grid .xl-col-5-8 {
    grid-column: 5/8;
  }
  .grid .xl-col-5-9 {
    grid-column: 5/9;
  }
  .grid .xl-col-5-10 {
    grid-column: 5/10;
  }
  .grid .xl-col-5-11 {
    grid-column: 5/11;
  }
  .grid .xl-col-5-12 {
    grid-column: 5/12;
  }
  .grid .xl-col-5-13 {
    grid-column: 5/13;
  }
  .grid .xl-col-6-1 {
    grid-column: 6/1;
  }
  .grid .xl-col-6-2 {
    grid-column: 6/2;
  }
  .grid .xl-col-6-3 {
    grid-column: 6/3;
  }
  .grid .xl-col-6-4 {
    grid-column: 6/4;
  }
  .grid .xl-col-6-5 {
    grid-column: 6/5;
  }
  .grid .xl-col-6-6 {
    grid-column: 6/6;
  }
  .grid .xl-col-6-7 {
    grid-column: 6/7;
  }
  .grid .xl-col-6-8 {
    grid-column: 6/8;
  }
  .grid .xl-col-6-9 {
    grid-column: 6/9;
  }
  .grid .xl-col-6-10 {
    grid-column: 6/10;
  }
  .grid .xl-col-6-11 {
    grid-column: 6/11;
  }
  .grid .xl-col-6-12 {
    grid-column: 6/12;
  }
  .grid .xl-col-6-13 {
    grid-column: 6/13;
  }
  .grid .xl-col-7-1 {
    grid-column: 7/1;
  }
  .grid .xl-col-7-2 {
    grid-column: 7/2;
  }
  .grid .xl-col-7-3 {
    grid-column: 7/3;
  }
  .grid .xl-col-7-4 {
    grid-column: 7/4;
  }
  .grid .xl-col-7-5 {
    grid-column: 7/5;
  }
  .grid .xl-col-7-6 {
    grid-column: 7/6;
  }
  .grid .xl-col-7-7 {
    grid-column: 7/7;
  }
  .grid .xl-col-7-8 {
    grid-column: 7/8;
  }
  .grid .xl-col-7-9 {
    grid-column: 7/9;
  }
  .grid .xl-col-7-10 {
    grid-column: 7/10;
  }
  .grid .xl-col-7-11 {
    grid-column: 7/11;
  }
  .grid .xl-col-7-12 {
    grid-column: 7/12;
  }
  .grid .xl-col-7-13 {
    grid-column: 7/13;
  }
  .grid .xl-col-8-1 {
    grid-column: 8/1;
  }
  .grid .xl-col-8-2 {
    grid-column: 8/2;
  }
  .grid .xl-col-8-3 {
    grid-column: 8/3;
  }
  .grid .xl-col-8-4 {
    grid-column: 8/4;
  }
  .grid .xl-col-8-5 {
    grid-column: 8/5;
  }
  .grid .xl-col-8-6 {
    grid-column: 8/6;
  }
  .grid .xl-col-8-7 {
    grid-column: 8/7;
  }
  .grid .xl-col-8-8 {
    grid-column: 8/8;
  }
  .grid .xl-col-8-9 {
    grid-column: 8/9;
  }
  .grid .xl-col-8-10 {
    grid-column: 8/10;
  }
  .grid .xl-col-8-11 {
    grid-column: 8/11;
  }
  .grid .xl-col-8-12 {
    grid-column: 8/12;
  }
  .grid .xl-col-8-13 {
    grid-column: 8/13;
  }
  .grid .xl-col-9-1 {
    grid-column: 9/1;
  }
  .grid .xl-col-9-2 {
    grid-column: 9/2;
  }
  .grid .xl-col-9-3 {
    grid-column: 9/3;
  }
  .grid .xl-col-9-4 {
    grid-column: 9/4;
  }
  .grid .xl-col-9-5 {
    grid-column: 9/5;
  }
  .grid .xl-col-9-6 {
    grid-column: 9/6;
  }
  .grid .xl-col-9-7 {
    grid-column: 9/7;
  }
  .grid .xl-col-9-8 {
    grid-column: 9/8;
  }
  .grid .xl-col-9-9 {
    grid-column: 9/9;
  }
  .grid .xl-col-9-10 {
    grid-column: 9/10;
  }
  .grid .xl-col-9-11 {
    grid-column: 9/11;
  }
  .grid .xl-col-9-12 {
    grid-column: 9/12;
  }
  .grid .xl-col-9-13 {
    grid-column: 9/13;
  }
  .grid .xl-col-10-1 {
    grid-column: 10/1;
  }
  .grid .xl-col-10-2 {
    grid-column: 10/2;
  }
  .grid .xl-col-10-3 {
    grid-column: 10/3;
  }
  .grid .xl-col-10-4 {
    grid-column: 10/4;
  }
  .grid .xl-col-10-5 {
    grid-column: 10/5;
  }
  .grid .xl-col-10-6 {
    grid-column: 10/6;
  }
  .grid .xl-col-10-7 {
    grid-column: 10/7;
  }
  .grid .xl-col-10-8 {
    grid-column: 10/8;
  }
  .grid .xl-col-10-9 {
    grid-column: 10/9;
  }
  .grid .xl-col-10-10 {
    grid-column: 10/10;
  }
  .grid .xl-col-10-11 {
    grid-column: 10/11;
  }
  .grid .xl-col-10-12 {
    grid-column: 10/12;
  }
  .grid .xl-col-10-13 {
    grid-column: 10/13;
  }
  .grid .xl-col-11-1 {
    grid-column: 11/1;
  }
  .grid .xl-col-11-2 {
    grid-column: 11/2;
  }
  .grid .xl-col-11-3 {
    grid-column: 11/3;
  }
  .grid .xl-col-11-4 {
    grid-column: 11/4;
  }
  .grid .xl-col-11-5 {
    grid-column: 11/5;
  }
  .grid .xl-col-11-6 {
    grid-column: 11/6;
  }
  .grid .xl-col-11-7 {
    grid-column: 11/7;
  }
  .grid .xl-col-11-8 {
    grid-column: 11/8;
  }
  .grid .xl-col-11-9 {
    grid-column: 11/9;
  }
  .grid .xl-col-11-10 {
    grid-column: 11/10;
  }
  .grid .xl-col-11-11 {
    grid-column: 11/11;
  }
  .grid .xl-col-11-12 {
    grid-column: 11/12;
  }
  .grid .xl-col-11-13 {
    grid-column: 11/13;
  }
  .grid .xl-col-12-1 {
    grid-column: 12/1;
  }
  .grid .xl-col-12-2 {
    grid-column: 12/2;
  }
  .grid .xl-col-12-3 {
    grid-column: 12/3;
  }
  .grid .xl-col-12-4 {
    grid-column: 12/4;
  }
  .grid .xl-col-12-5 {
    grid-column: 12/5;
  }
  .grid .xl-col-12-6 {
    grid-column: 12/6;
  }
  .grid .xl-col-12-7 {
    grid-column: 12/7;
  }
  .grid .xl-col-12-8 {
    grid-column: 12/8;
  }
  .grid .xl-col-12-9 {
    grid-column: 12/9;
  }
  .grid .xl-col-12-10 {
    grid-column: 12/10;
  }
  .grid .xl-col-12-11 {
    grid-column: 12/11;
  }
  .grid .xl-col-12-12 {
    grid-column: 12/12;
  }
  .grid .xl-col-12-13 {
    grid-column: 12/13;
  }
  .grid .xl-col-13-1 {
    grid-column: 13/1;
  }
  .grid .xl-col-13-2 {
    grid-column: 13/2;
  }
  .grid .xl-col-13-3 {
    grid-column: 13/3;
  }
  .grid .xl-col-13-4 {
    grid-column: 13/4;
  }
  .grid .xl-col-13-5 {
    grid-column: 13/5;
  }
  .grid .xl-col-13-6 {
    grid-column: 13/6;
  }
  .grid .xl-col-13-7 {
    grid-column: 13/7;
  }
  .grid .xl-col-13-8 {
    grid-column: 13/8;
  }
  .grid .xl-col-13-9 {
    grid-column: 13/9;
  }
  .grid .xl-col-13-10 {
    grid-column: 13/10;
  }
  .grid .xl-col-13-11 {
    grid-column: 13/11;
  }
  .grid .xl-col-13-12 {
    grid-column: 13/12;
  }
  .grid .xl-col-13-13 {
    grid-column: 13/13;
  }
}
@font-face {
  font-family: calluna;
  src: url("https://use.typekit.net/af/bccc98/000000000000000000014868/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/bccc98/000000000000000000014868/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/bccc98/000000000000000000014868/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: calluna;
  src: url("https://use.typekit.net/af/32f92a/000000000000000000014869/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/32f92a/000000000000000000014869/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/32f92a/000000000000000000014869/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: auto;
}
body {
  font-family: calluna;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

strong,
.copyright p {
  font-family: "Lato", sans-serif;
}

.copyright p {
  font-weight: 300;
}

h1 {
  font-weight: 400;
}

small,
.text_small {
  font-size: 0.8rem;
}

.card {
  position: relative;
  word-wrap: break-word;
  margin-top: 0.8rem;
  background-color: var(--white);
  margin-bottom: 16px;
  color: #4a4a4a;
}
.card:not([data-border=no]) {
  border-radius: 6px;
}
.card:not([data-shadow="0"]) {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
}
.card[data-theme=dark] {
  background: #2b2a33;
}
.card .card__header {
  font-weight: 400;
  color: #3f414d;
  background-color: #edf2f9;
}
.card .card__header:not([data-padding=no], [data-padding="0"]) {
  padding: 1.4rem 1.25rem;
}
.card .card__body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 1.5;
}
.card .card__body:not([data-padding="0"]) {
  padding: 1rem 1.2rem;
}
.card[data-margin=auto] {
  max-width: 480px;
  margin: 0 auto;
}
.card:hover[data-shadow="0"] {
  border-radius: 4px;
  overflow: hidden;
  transform: translateY(-2px);
}

.loading {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column: 1;
  position: absolute;
  inset: 0;
  height: 100%;
  display: grid;
  place-content: center;
  background-color: var(--white);
  z-index: 999;
  transition: all 0.5s ease-in-out;
}
.loading:before {
  content: "";
  width: 80px;
  height: 80px;
  border-style: solid;
  border-color: var(--color-1);
  border-width: 2px 0 0 0;
  border-radius: 100px;
  -webkit-animation: spinner 1s linear 0s infinite normal none running;
  animation: spinner 1s linear 0s infinite normal none running;
}
.loading:after {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("./logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3em;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
:root {
  --md-source: #002060;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #00174b;
  --md-ref-palette-primary20: #002a78;
  --md-ref-palette-primary25: #133684;
  --md-ref-palette-primary30: #234290;
  --md-ref-palette-primary35: #314e9c;
  --md-ref-palette-primary40: #3e5aa9;
  --md-ref-palette-primary50: #5873c4;
  --md-ref-palette-primary60: #728de0;
  --md-ref-palette-primary70: #8da8fd;
  --md-ref-palette-primary80: #b4c5ff;
  --md-ref-palette-primary90: #dbe1ff;
  --md-ref-palette-primary95: #eef0ff;
  --md-ref-palette-primary98: #faf8ff;
  --md-ref-palette-primary99: #fefbff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #001e2b;
  --md-ref-palette-secondary20: #003548;
  --md-ref-palette-secondary25: #004058;
  --md-ref-palette-secondary30: #004d67;
  --md-ref-palette-secondary35: #005978;
  --md-ref-palette-secondary40: #006688;
  --md-ref-palette-secondary50: #0080aa;
  --md-ref-palette-secondary60: #1e9bcb;
  --md-ref-palette-secondary70: #49b6e8;
  --md-ref-palette-secondary80: #76d1ff;
  --md-ref-palette-secondary90: #c2e8ff;
  --md-ref-palette-secondary95: #e2f3ff;
  --md-ref-palette-secondary98: #f5faff;
  --md-ref-palette-secondary99: #fbfcff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #001e2f;
  --md-ref-palette-tertiary20: #00344d;
  --md-ref-palette-tertiary25: #00405d;
  --md-ref-palette-tertiary30: #004c6e;
  --md-ref-palette-tertiary35: #00587f;
  --md-ref-palette-tertiary40: #006590;
  --md-ref-palette-tertiary50: #007fb4;
  --md-ref-palette-tertiary60: #3499d1;
  --md-ref-palette-tertiary70: #56b4ee;
  --md-ref-palette-tertiary80: #88ceff;
  --md-ref-palette-tertiary90: #c8e6ff;
  --md-ref-palette-tertiary95: #e5f2ff;
  --md-ref-palette-tertiary98: #f6faff;
  --md-ref-palette-tertiary99: #fbfcff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #1b1b1f;
  --md-ref-palette-neutral20: #303034;
  --md-ref-palette-neutral25: #3b3b3f;
  --md-ref-palette-neutral30: #46464a;
  --md-ref-palette-neutral35: #525256;
  --md-ref-palette-neutral40: #5e5e62;
  --md-ref-palette-neutral50: #77767a;
  --md-ref-palette-neutral60: #919094;
  --md-ref-palette-neutral70: #acaaaf;
  --md-ref-palette-neutral80: #c7c6ca;
  --md-ref-palette-neutral90: #e4e2e6;
  --md-ref-palette-neutral95: #f2f0f4;
  --md-ref-palette-neutral98: #fbf8fd;
  --md-ref-palette-neutral99: #fefbff;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #191b23;
  --md-ref-palette-neutral-variant20: #2e3038;
  --md-ref-palette-neutral-variant25: #393b43;
  --md-ref-palette-neutral-variant30: #45464f;
  --md-ref-palette-neutral-variant35: #51525a;
  --md-ref-palette-neutral-variant40: #5d5e67;
  --md-ref-palette-neutral-variant50: #757680;
  --md-ref-palette-neutral-variant60: #8f909a;
  --md-ref-palette-neutral-variant70: #aaaab4;
  --md-ref-palette-neutral-variant80: #c5c6d0;
  --md-ref-palette-neutral-variant90: #e2e2ec;
  --md-ref-palette-neutral-variant95: #f0f0fa;
  --md-ref-palette-neutral-variant98: #faf8ff;
  --md-ref-palette-neutral-variant99: #fefbff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #3e5aa9;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #dbe1ff;
  --md-sys-color-on-primary-container-light: #00174b;
  --md-sys-color-secondary-light: #006688;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #c2e8ff;
  --md-sys-color-on-secondary-container-light: #001e2b;
  --md-sys-color-tertiary-light: #006590;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #c8e6ff;
  --md-sys-color-on-tertiary-container-light: #001e2f;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fefbff;
  --md-sys-color-on-background-light: #1b1b1f;
  --md-sys-color-surface-light: #fefbff;
  --md-sys-color-on-surface-light: #1b1b1f;
  --md-sys-color-surface-variant-light: #e2e2ec;
  --md-sys-color-on-surface-variant-light: #45464f;
  --md-sys-color-outline-light: #757680;
  --md-sys-color-inverse-on-surface-light: #f2f0f4;
  --md-sys-color-inverse-surface-light: #303034;
  --md-sys-color-inverse-primary-light: #b4c5ff;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #3e5aa9;
  --md-sys-color-surface-tint-color-light: #3e5aa9;
  /* dark */
  --md-sys-color-primary-dark: #b4c5ff;
  --md-sys-color-on-primary-dark: #002a78;
  --md-sys-color-primary-container-dark: #234290;
  --md-sys-color-on-primary-container-dark: #dbe1ff;
  --md-sys-color-secondary-dark: #76d1ff;
  --md-sys-color-on-secondary-dark: #003548;
  --md-sys-color-secondary-container-dark: #004d67;
  --md-sys-color-on-secondary-container-dark: #c2e8ff;
  --md-sys-color-tertiary-dark: #88ceff;
  --md-sys-color-on-tertiary-dark: #00344d;
  --md-sys-color-tertiary-container-dark: #004c6e;
  --md-sys-color-on-tertiary-container-dark: #c8e6ff;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #1b1b1f;
  --md-sys-color-on-background-dark: #e4e2e6;
  --md-sys-color-surface-dark: #1b1b1f;
  --md-sys-color-on-surface-dark: #e4e2e6;
  --md-sys-color-surface-variant-dark: #45464f;
  --md-sys-color-on-surface-variant-dark: #c5c6d0;
  --md-sys-color-outline-dark: #8f909a;
  --md-sys-color-inverse-on-surface-dark: #1b1b1f;
  --md-sys-color-inverse-surface-dark: #e4e2e6;
  --md-sys-color-inverse-primary-dark: #3e5aa9;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #b4c5ff;
  --md-sys-color-surface-tint-color-dark: #b4c5ff;
  /* Custom Colors */
  --md-custom-color-Custom-Color-1: #85023b;
  --md-custom-color-Custom-Color-2: #3d7aae;
  --md-custom-color-Custom-Color-3: #cc9402;
  --md-custom-color-Custom-Color-4: #1a0a7a;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  --md-sys-typescale-label-small-line-height: 16px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  --md-sys-typescale-label-medium-line-height: 16px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  --md-sys-typescale-label-large-line-height: 20px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  --md-sys-typescale-body-small-line-height: 16px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  --md-sys-typescale-body-medium-line-height: 20px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  --md-sys-typescale-body-large-line-height: 24px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  --md-sys-typescale-headline-small-line-height: 32px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  --md-sys-typescale-headline-large-line-height: 40px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  --md-sys-typescale-display-small-line-height: 44px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  --md-sys-typescale-display-medium-line-height: 52px;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  --md-sys-typescale-display-large-line-height: 64px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
  --md-sys-typescale-title-small-line-height: 20px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  --md-sys-typescale-title-medium-line-height: 24px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  --md-sys-typescale-title-large-line-height: 28px;
}

.primary {
  background-color: var(--md-sys-color-primary);
}

.primary-text {
  color: var(--md-sys-color-primary);
}

.on-primary {
  background-color: var(--md-sys-color-on-primary);
}

.on-primary-text {
  color: var(--md-sys-color-on-primary);
}

.primary-container {
  background-color: var(--md-sys-color-primary-container);
}

.primary-container-text {
  color: var(--md-sys-color-primary-container);
}

.on-primary-container {
  background-color: var(--md-sys-color-on-primary-container);
}

.on-primary-container-text {
  color: var(--md-sys-color-on-primary-container);
}

.secondary {
  background-color: var(--md-sys-color-secondary);
}

.secondary-text {
  color: var(--md-sys-color-secondary);
}

.on-secondary {
  background-color: var(--md-sys-color-on-secondary);
}

.on-secondary-text {
  color: var(--md-sys-color-on-secondary);
}

.secondary-container {
  background-color: var(--md-sys-color-secondary-container);
}

.secondary-container-text {
  color: var(--md-sys-color-secondary-container);
}

.on-secondary-container {
  background-color: var(--md-sys-color-on-secondary-container);
}

.on-secondary-container-text {
  color: var(--md-sys-color-on-secondary-container);
}

.tertiary {
  background-color: var(--md-sys-color-tertiary);
}

.tertiary-text {
  color: var(--md-sys-color-tertiary);
}

.on-tertiary {
  background-color: var(--md-sys-color-on-tertiary);
}

.on-tertiary-text {
  color: var(--md-sys-color-on-tertiary);
}

.tertiary-container {
  background-color: var(--md-sys-color-tertiary-container);
}

.tertiary-container-text {
  color: var(--md-sys-color-tertiary-container);
}

.on-tertiary-container {
  background-color: var(--md-sys-color-on-tertiary-container);
}

.on-tertiary-container-text {
  color: var(--md-sys-color-on-tertiary-container);
}

.error {
  background-color: var(--md-sys-color-error);
}

.error-text {
  color: var(--md-sys-color-error);
}

.error-container {
  background-color: var(--md-sys-color-error-container);
}

.error-container-text {
  color: var(--md-sys-color-error-container);
}

.on-error {
  background-color: var(--md-sys-color-on-error);
}

.on-error-text {
  color: var(--md-sys-color-on-error);
}

.on-error-container {
  background-color: var(--md-sys-color-on-error-container);
}

.on-error-container-text {
  color: var(--md-sys-color-on-error-container);
}

.background {
  background-color: var(--md-sys-color-background);
}

.background-text {
  color: var(--md-sys-color-background);
}

.on-background {
  background-color: var(--md-sys-color-on-background);
}

.on-background-text {
  color: var(--md-sys-color-on-background);
}

.surface {
  background-color: var(--md-sys-color-surface);
}

.surface-text {
  color: var(--md-sys-color-surface);
}

.on-surface {
  background-color: var(--md-sys-color-on-surface);
}

.on-surface-text {
  color: var(--md-sys-color-on-surface);
}

.surface-variant {
  background-color: var(--md-sys-color-surface-variant);
}

.surface-variant-text {
  color: var(--md-sys-color-surface-variant);
}

.on-surface-variant {
  background-color: var(--md-sys-color-on-surface-variant);
}

.on-surface-variant-text {
  color: var(--md-sys-color-on-surface-variant);
}

.outline {
  background-color: var(--md-sys-color-outline);
}

.outline-text {
  color: var(--md-sys-color-outline);
}

.inverse-on-surface {
  background-color: var(--md-sys-color-inverse-on-surface);
}

.inverse-on-surface-text {
  color: var(--md-sys-color-inverse-on-surface);
}

.inverse-surface {
  background-color: var(--md-sys-color-inverse-surface);
}

.inverse-surface-text {
  color: var(--md-sys-color-inverse-surface);
}

.inverse-primary {
  background-color: var(--md-sys-color-inverse-primary);
}

.inverse-primary-text {
  color: var(--md-sys-color-inverse-primary);
}

.shadow {
  background-color: var(--md-sys-color-shadow);
}

.shadow-text {
  color: var(--md-sys-color-shadow);
}

.surface-tint {
  background-color: var(--md-sys-color-surface-tint);
}

.surface-tint-text {
  color: var(--md-sys-color-surface-tint);
}

.surface-tint-color {
  background-color: var(--md-sys-color-surface-tint-color);
}

.surface-tint-color-text {
  color: var(--md-sys-color-surface-tint-color);
}

.label-small {
  font-family: var(--md-sys-typescale-label-small-font-family-name);
  font-style: var(--md-sys-typescale-label-small-font-family-style);
  font-weight: var(--md-sys-typescale-label-small-font-weight);
  font-size: var(--md-sys-typescale-label-small-font-size);
  letter-spacing: var(--md-sys-typescale-label-small-tracking);
  line-height: var(--md-sys-typescale-label-small-height);
  text-transform: var(--md-sys-typescale-label-small-text-transform);
  text-decoration: var(--md-sys-typescale-label-small-text-decoration);
}

.label-medium {
  font-family: var(--md-sys-typescale-label-medium-font-family-name);
  font-style: var(--md-sys-typescale-label-medium-font-family-style);
  font-weight: var(--md-sys-typescale-label-medium-font-weight);
  font-size: var(--md-sys-typescale-label-medium-font-size);
  letter-spacing: var(--md-sys-typescale-label-medium-tracking);
  line-height: var(--md-sys-typescale-label-medium-height);
  text-transform: var(--md-sys-typescale-label-medium-text-transform);
  text-decoration: var(--md-sys-typescale-label-medium-text-decoration);
}

.label-large {
  font-family: var(--md-sys-typescale-label-large-font-family-name);
  font-style: var(--md-sys-typescale-label-large-font-family-style);
  font-weight: var(--md-sys-typescale-label-large-font-weight);
  font-size: var(--md-sys-typescale-label-large-font-size);
  letter-spacing: var(--md-sys-typescale-label-large-tracking);
  line-height: var(--md-sys-typescale-label-large-height);
  text-transform: var(--md-sys-typescale-label-large-text-transform);
  text-decoration: var(--md-sys-typescale-label-large-text-decoration);
}

.body-small {
  font-family: var(--md-sys-typescale-body-small-font-family-name);
  font-style: var(--md-sys-typescale-body-small-font-family-style);
  font-weight: var(--md-sys-typescale-body-small-font-weight);
  font-size: var(--md-sys-typescale-body-small-font-size);
  letter-spacing: var(--md-sys-typescale-body-small-tracking);
  line-height: var(--md-sys-typescale-body-small-height);
  text-transform: var(--md-sys-typescale-body-small-text-transform);
  text-decoration: var(--md-sys-typescale-body-small-text-decoration);
}

.body-medium {
  font-family: var(--md-sys-typescale-body-medium-font-family-name);
  font-style: var(--md-sys-typescale-body-medium-font-family-style);
  font-weight: var(--md-sys-typescale-body-medium-font-weight);
  font-size: var(--md-sys-typescale-body-medium-font-size);
  letter-spacing: var(--md-sys-typescale-body-medium-tracking);
  line-height: var(--md-sys-typescale-body-medium-height);
  text-transform: var(--md-sys-typescale-body-medium-text-transform);
  text-decoration: var(--md-sys-typescale-body-medium-text-decoration);
}

.body-large {
  font-family: var(--md-sys-typescale-body-large-font-family-name);
  font-style: var(--md-sys-typescale-body-large-font-family-style);
  font-weight: var(--md-sys-typescale-body-large-font-weight);
  font-size: var(--md-sys-typescale-body-large-font-size);
  letter-spacing: var(--md-sys-typescale-body-large-tracking);
  line-height: var(--md-sys-typescale-body-large-height);
  text-transform: var(--md-sys-typescale-body-large-text-transform);
  text-decoration: var(--md-sys-typescale-body-large-text-decoration);
}

.headline-small {
  font-family: var(--md-sys-typescale-headline-small-font-family-name);
  font-style: var(--md-sys-typescale-headline-small-font-family-style);
  font-weight: var(--md-sys-typescale-headline-small-font-weight);
  font-size: var(--md-sys-typescale-headline-small-font-size);
  letter-spacing: var(--md-sys-typescale-headline-small-tracking);
  line-height: var(--md-sys-typescale-headline-small-height);
  text-transform: var(--md-sys-typescale-headline-small-text-transform);
  text-decoration: var(--md-sys-typescale-headline-small-text-decoration);
}

.headline-medium {
  font-family: var(--md-sys-typescale-headline-medium-font-family-name);
  font-style: var(--md-sys-typescale-headline-medium-font-family-style);
  font-weight: var(--md-sys-typescale-headline-medium-font-weight);
  font-size: var(--md-sys-typescale-headline-medium-font-size);
  letter-spacing: var(--md-sys-typescale-headline-medium-tracking);
  line-height: var(--md-sys-typescale-headline-medium-height);
  text-transform: var(--md-sys-typescale-headline-medium-text-transform);
  text-decoration: var(--md-sys-typescale-headline-medium-text-decoration);
}

.headline-large {
  font-family: var(--md-sys-typescale-headline-large-font-family-name);
  font-style: var(--md-sys-typescale-headline-large-font-family-style);
  font-weight: var(--md-sys-typescale-headline-large-font-weight);
  font-size: var(--md-sys-typescale-headline-large-font-size);
  letter-spacing: var(--md-sys-typescale-headline-large-tracking);
  line-height: var(--md-sys-typescale-headline-large-height);
  text-transform: var(--md-sys-typescale-headline-large-text-transform);
  text-decoration: var(--md-sys-typescale-headline-large-text-decoration);
}

.display-small {
  font-family: var(--md-sys-typescale-display-small-font-family-name);
  font-style: var(--md-sys-typescale-display-small-font-family-style);
  font-weight: var(--md-sys-typescale-display-small-font-weight);
  font-size: var(--md-sys-typescale-display-small-font-size);
  letter-spacing: var(--md-sys-typescale-display-small-tracking);
  line-height: var(--md-sys-typescale-display-small-height);
  text-transform: var(--md-sys-typescale-display-small-text-transform);
  text-decoration: var(--md-sys-typescale-display-small-text-decoration);
}

.display-medium {
  font-family: var(--md-sys-typescale-display-medium-font-family-name);
  font-style: var(--md-sys-typescale-display-medium-font-family-style);
  font-weight: var(--md-sys-typescale-display-medium-font-weight);
  font-size: var(--md-sys-typescale-display-medium-font-size);
  letter-spacing: var(--md-sys-typescale-display-medium-tracking);
  line-height: var(--md-sys-typescale-display-medium-height);
  text-transform: var(--md-sys-typescale-display-medium-text-transform);
  text-decoration: var(--md-sys-typescale-display-medium-text-decoration);
}

.display-large {
  font-family: var(--md-sys-typescale-display-large-font-family-name);
  font-style: var(--md-sys-typescale-display-large-font-family-style);
  font-weight: var(--md-sys-typescale-display-large-font-weight);
  font-size: var(--md-sys-typescale-display-large-font-size);
  letter-spacing: var(--md-sys-typescale-display-large-tracking);
  line-height: var(--md-sys-typescale-display-large-height);
  text-transform: var(--md-sys-typescale-display-large-text-transform);
  text-decoration: var(--md-sys-typescale-display-large-text-decoration);
}

.title-small {
  font-family: var(--md-sys-typescale-title-small-font-family-name);
  font-style: var(--md-sys-typescale-title-small-font-family-style);
  font-weight: var(--md-sys-typescale-title-small-font-weight);
  font-size: var(--md-sys-typescale-title-small-font-size);
  letter-spacing: var(--md-sys-typescale-title-small-tracking);
  line-height: var(--md-sys-typescale-title-small-height);
  text-transform: var(--md-sys-typescale-title-small-text-transform);
  text-decoration: var(--md-sys-typescale-title-small-text-decoration);
}

.title-medium {
  font-family: var(--md-sys-typescale-title-medium-font-family-name);
  font-style: var(--md-sys-typescale-title-medium-font-family-style);
  font-weight: var(--md-sys-typescale-title-medium-font-weight);
  font-size: var(--md-sys-typescale-title-medium-font-size);
  letter-spacing: var(--md-sys-typescale-title-medium-tracking);
  line-height: var(--md-sys-typescale-title-medium-height);
  text-transform: var(--md-sys-typescale-title-medium-text-transform);
  text-decoration: var(--md-sys-typescale-title-medium-text-decoration);
}

.title-large {
  font-family: var(--md-sys-typescale-title-large-font-family-name);
  font-style: var(--md-sys-typescale-title-large-font-family-style);
  font-weight: var(--md-sys-typescale-title-large-font-weight);
  font-size: var(--md-sys-typescale-title-large-font-size);
  letter-spacing: var(--md-sys-typescale-title-large-tracking);
  line-height: var(--md-sys-typescale-title-large-height);
  text-transform: var(--md-sys-typescale-title-large-text-transform);
  text-decoration: var(--md-sys-typescale-title-large-text-decoration);
}

@media (prefers-color-scheme: light) {
  :root {
    --md-sys-color-primary: var(--md-sys-color-primary-light);
    --md-sys-color-on-primary: var(--md-sys-color-on-primary-light);
    --md-sys-color-primary-container: var(--md-sys-color-primary-container-light);
    --md-sys-color-on-primary-container: var(--md-sys-color-on-primary-container-light);
    --md-sys-color-secondary: var(--md-sys-color-secondary-light);
    --md-sys-color-on-secondary: var(--md-sys-color-on-secondary-light);
    --md-sys-color-secondary-container: var(--md-sys-color-secondary-container-light);
    --md-sys-color-on-secondary-container: var(--md-sys-color-on-secondary-container-light);
    --md-sys-color-tertiary: var(--md-sys-color-tertiary-light);
    --md-sys-color-on-tertiary: var(--md-sys-color-on-tertiary-light);
    --md-sys-color-tertiary-container: var(--md-sys-color-tertiary-container-light);
    --md-sys-color-on-tertiary-container: var(--md-sys-color-on-tertiary-container-light);
    --md-sys-color-error: var(--md-sys-color-error-light);
    --md-sys-color-error-container: var(--md-sys-color-error-container-light);
    --md-sys-color-on-error: var(--md-sys-color-on-error-light);
    --md-sys-color-on-error-container: var(--md-sys-color-on-error-container-light);
    --md-sys-color-background: var(--md-sys-color-background-light);
    --md-sys-color-on-background: var(--md-sys-color-on-background-light);
    --md-sys-color-surface: var(--md-sys-color-surface-light);
    --md-sys-color-on-surface: var(--md-sys-color-on-surface-light);
    --md-sys-color-surface-variant: var(--md-sys-color-surface-variant-light);
    --md-sys-color-on-surface-variant: var(--md-sys-color-on-surface-variant-light);
    --md-sys-color-outline: var(--md-sys-color-outline-light);
    --md-sys-color-inverse-on-surface: var(--md-sys-color-inverse-on-surface-light);
    --md-sys-color-inverse-surface: var(--md-sys-color-inverse-surface-light);
    --md-sys-color-inverse-primary: var(--md-sys-color-inverse-primary-light);
    --md-sys-color-shadow: var(--md-sys-color-shadow-light);
    --md-sys-color-surface-tint: var(--md-sys-color-surface-tint-light);
    --md-sys-color-surface-tint-color: var(--md-sys-color-surface-tint-color-light);
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --md-sys-color-primary: var(--md-sys-color-primary-dark);
    --md-sys-color-on-primary: var(--md-sys-color-on-primary-dark);
    --md-sys-color-primary-container: var(--md-sys-color-primary-container-dark);
    --md-sys-color-on-primary-container: var(--md-sys-color-on-primary-container-dark);
    --md-sys-color-secondary: var(--md-sys-color-secondary-dark);
    --md-sys-color-on-secondary: var(--md-sys-color-on-secondary-dark);
    --md-sys-color-secondary-container: var(--md-sys-color-secondary-container-dark);
    --md-sys-color-on-secondary-container: var(--md-sys-color-on-secondary-container-dark);
    --md-sys-color-tertiary: var(--md-sys-color-tertiary-dark);
    --md-sys-color-on-tertiary: var(--md-sys-color-on-tertiary-dark);
    --md-sys-color-tertiary-container: var(--md-sys-color-tertiary-container-dark);
    --md-sys-color-on-tertiary-container: var(--md-sys-color-on-tertiary-container-dark);
    --md-sys-color-error: var(--md-sys-color-error-dark);
    --md-sys-color-error-container: var(--md-sys-color-error-container-dark);
    --md-sys-color-on-error: var(--md-sys-color-on-error-dark);
    --md-sys-color-on-error-container: var(--md-sys-color-on-error-container-dark);
    --md-sys-color-background: var(--md-sys-color-background-dark);
    --md-sys-color-on-background: var(--md-sys-color-on-background-dark);
    --md-sys-color-surface: var(--md-sys-color-surface-dark);
    --md-sys-color-on-surface: var(--md-sys-color-on-surface-dark);
    --md-sys-color-surface-variant: var(--md-sys-color-surface-variant-dark);
    --md-sys-color-on-surface-variant: var(--md-sys-color-on-surface-variant-dark);
    --md-sys-color-outline: var(--md-sys-color-outline-dark);
    --md-sys-color-inverse-on-surface: var(--md-sys-color-inverse-on-surface-dark);
    --md-sys-color-inverse-surface: var(--md-sys-color-inverse-surface-dark);
    --md-sys-color-inverse-primary: var(--md-sys-color-inverse-primary-dark);
    --md-sys-color-shadow: var(--md-sys-color-shadow-dark);
    --md-sys-color-surface-tint: var(--md-sys-color-surface-tint-dark);
    --md-sys-color-surface-tint-color: var(--md-sys-color-surface-tint-color-dark);
  }
}
html,
body {
  overflow-x: hidden;
}

header ul,
footer ul {
  list-style-type: none;
  *margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

article {
  height: 100%;
}

.relative {
  position: relative;
}

div[class*=__frame] {
  margin: 0 auto;
  height: inherit;
}
div[class*=__frame][data-width=small] {
  max-width: 479px;
}
div[class*=__frame][data-width=medium] {
  max-width: 639px;
}
div[class*=__frame][data-width=large] {
  max-width: 1023px;
}
div[class*=__frame][data-width=custom] {
  max-width: 1203px;
}
div[class*=__frame][data-width=xlarge] {
  max-width: 1365px;
}
div[class*=__frame][data-width=xxlarge] {
  max-width: 1919px;
}
div[class*=__frame][data-width=xxxlarge] {
  max-width: 100%;
}

:root {
  --font-size-base: clamp(1rem, 0.34vw + 0.91rem, 1.19rem);
  --font-size-lg: clamp(1.2rem, 0.7vw + 1.2rem, 1.5rem);
  --font-size-xl: clamp(1.1rem, 1.18vw + 1.85rem, 3.75rem);
  --color-text: hsla(0, 39%, 95%, 0.9);
  --color-bg: #fff;
  --color-border: hsl(17, 24%, 90%);
  --link-gradient: linear-gradient(45deg, #9eeff7, #17a8d4 30%, var(--color-border) 60%);
  --font-family: system-ui, sans-serif;
  --frosty-gradient: linear-gradient(0deg, #9eeff7 1.2%, #17a8d4 70.75%);
  --primary-color: #002060;
  --secondary-color: #005E7E;
}

html,
body {
  min-height: 100vh;
  height: 100%;
}

html {
  font-size: var(--font-size-base);
  background-color: var(--color-bg);
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: var(--color-bg);
}

.motion {
  background-color: var(--white);
  position: fixed;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: grid;
  place-items: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 999;
}

#root {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-areas: "header" "main" "footer";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
}
#root:before {
  content: "";
  position: absolute;
  inset: 0;
  height: 100%;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(4px);
  z-index: -1;
}
#root:after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.33);
  z-index: -1;
}

header,
main,
footer {
  position: relative;
  *place-self: center;
}

header {
  grid-area: header;
  background: #FFFFFF;
  border-bottom: 9px solid var(--primary-color);
  position: relative;
}
header figure {
  cursor: pointer;
}
header figure img {
  width: 311px;
}
header .pc-nav li {
  margin-left: 1rem;
}
header .pc-nav li a,
header .pc-nav li .nav-btn {
  color: #343434;
  font-size: 1.2rem;
}
@media (max-width: 576px) {
  header {
    height: 90px;
    padding: 0.6rem 0.5rem;
  }
  header .pc-nav {
    display: none;
  }
  header figure img {
    width: 211px;
  }
}
@media (min-width: 576px) {
  header {
    height: 120px;
    padding: 0.6rem 0;
  }
  header .menu {
    display: none;
  }
  header figure img {
    width: 311px;
  }
}
header i {
  user-select: none;
  font-size: 3.5rem !important;
  cursor: pointer;
}
header nav {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: var(--white);
  display: none;
  height: 0;
  transition: all 200ms linear;
  border-bottom: 9px solid var(--primary-color);
}
header nav ul li {
  width: 50%;
  display: inline-block;
  min-height: 6rem;
  vertical-align: middle;
  padding: 1rem;
}
header nav ul li p {
  max-width: 400px;
  color: var(--black);
  font-family: calluna;
}
@media (max-width: 576px) {
  header nav ul li {
    display: block;
    width: 100%;
  }
  header nav ul li p {
    max-width: 100% !important;
  }
}
header nav ul li .nav-btn {
  display: block;
  font-size: 0.8rem;
}
header nav ul li span {
  font-weight: 900;
  color: var(--primary-color);
}
header .show {
  display: block !important;
  height: auto;
  transition: all 200ms linear;
}

main {
  grid-area: main;
}
main article section {
  position: relative;
}
main h1.welcome,
main h2.slogan {
  text-align: center;
}
main p.message {
  line-height: 1.6;
  margin: 1rem 0;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 0.4rem;
  color: var(--color-bg);
}

footer {
  grid-area: footer;
  color: #1a1a1a;
  padding: 2rem 1rem 0 1rem;
  background: #cc9402;
}
footer a {
  color: var(--white);
}
footer figure img {
  max-width: 300px;
}
@media (min-width: 500px) {
  footer figure img {
    margin: 0 auto;
  }
}
footer p {
  color: var(--white);
}
@media (max-width: 500px) {
  footer ul {
    flex-direction: row !important;
    justify-content: space-between;
    flex-grow: 1 !important;
  }
}
footer ul li a {
  display: block;
  font-weight: 300;
  margin-top: 0.5rem;
}
footer p {
  margin: 2rem 0;
}
footer .copyright {
  padding: 1.1rem 0;
}
@media (max-width: 500px) {
  footer .copyright a {
    text-align: center;
  }
}
@media (min-width: 500px) {
  footer .copyright a {
    text-align: right;
  }
}

.heading {
  padding: 2rem 6rem;
  color: var(--white);
}
@media (max-width: 500px) {
  .heading {
    padding: 2rem 2.5rem;
  }
}
.heading h1 {
  margin-bottom: 1rem;
}
.heading p {
  font-weight: 300;
  text-transform: initial;
}

.popup {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column: 1/1;
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: 999;
}
.popup .content {
  position: relative;
  height: calc(100vh - 6rem);
  margin: 3rem 1rem;
  background: #FFFFFF;
  border: 1px solid #CAD6E2;
  border-radius: 16px;
  color: #333;
  padding: 2em;
}
.popup .content h1 {
  margin-bottom: 2rem;
}
.popup .content .close {
  position: absolute;
  left: calc(50% - 40px);
  top: -40px;
  width: 80px;
  height: 80px;
  padding: 1rem;
  background: #F7F8FA;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.25);
  border-radius: 44px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.grid__item * {
  z-index: 2;
}

.link-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(34ch, 1fr));
  gap: 1rem;
  padding: 0;
}
.link-card-grid li {
  list-style: none;
  display: flex;
  padding: 0.15rem;
  background-image: var(--link-gradient);
  background-size: 400%;
  border-radius: 0.5rem;
  background-position: 100%;
  transition: background-position 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}
.link-card-grid li:is(:hover, :focus-within) {
  background-position: 0;
}
.link-card-grid li:is(:hover, :focus-within) h2 {
  color: #fff;
}
.link-card-grid li:is(:hover, :focus-within) span {
  transform: translateX(2px);
}
.link-card-grid li a {
  width: 100%;
  text-decoration: none;
  line-height: 1.4;
  padding: 0.5em 1.3em;
  border-radius: 0.35rem;
  color: #333;
  border: 3px solid #17a8d4;
  opacity: 0.8;
}
.link-card-grid li h2 {
  margin: 0;
  transition: color 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}
.link-card-grid li h2 span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}
.link-card-grid li p {
  margin-top: 0.75rem;
  margin-bottom: 0;
}

.full-width {
  width: 100%;
}

.text-gradient {
  font-weight: 900;
  background-image: var(--frosty-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 200%;
  background-position-y: 100%;
  border-radius: 0.4rem;
  animation: pulse 4s ease-in-out infinite;
}

@keyframes pulse {
  0%, 100% {
    background-position-y: 0%;
  }
  50% {
    background-position-y: 80%;
  }
}


