.d-flex {
    display: -ms-flexbox;
    display: flex;
  }
  
  .d-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  
  .flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .flex-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  
  .flex-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .flex-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  
  .justify-content-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .justify-content-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  
  .justify-content-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .justify-content-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .justify-content-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  
  .align-items-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .align-items-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .align-items-center {
    -ms-flex-align: center;
    align-items: center;
  }
  
  .align-items-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  
  .align-items-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  
  .align-self-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  
  .align-self-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  
  .align-self-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  
  .align-self-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  
  .flex-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  
  .flex-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  
  .flex-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  
  .flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  
  .flex-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  
  .order-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  
  .order-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  
  .order-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  
  .align-content-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  
  .align-content-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  
  .align-content-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  
  .align-content-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  
  .align-content-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  
  .align-content-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  