:root {
  --white: #fff;
  --black: #0c0d0e;
  --orange: #f48024;
  --yellow: #fbf2d4;
  --green: #5eba7d;
  --blue: #0077cc;
  --powder: #e1ecf4;
  --red: #d1383d;
  --black-025: #fafafb;
  --black-050: #eff0f1;
  --black-075: #e4e6e8;
  --black-100: #d6d9dc;
  --black-150: #c8ccd0;
  --black-200: #bbc0c4;
  --black-300: #9fa6ad;
  --black-350: #9199a1;
  --black-400: #848d95;
  --black-500: #6a737c;
  --black-600: #535a60;
  --black-700: #3c4146;
  --black-750: #2f3337;
  --black-800: #242729;
  --black-900: #0c0d0e;
  --orange-050: #fff7f2;
  --orange-100: #fee3cf;
  --orange-200: #fcd0ad;
  --orange-300: #f7aa6d;
  --orange-400: #f48024;
  --orange-500: #f2720c;
  --orange-600: #da670b;
  --orange-700: #bd5c00;
  --orange-800: #a35200;
  --orange-900: #874600;
  --blue-050: #f2f9ff;
  --blue-100: #cfeafe;
  --blue-200: #addafc;
  --blue-300: #6cbbf7;
  --blue-400: #379fef;
  --blue-500: #0095ff;
  --blue-600: #0077cc;
  --blue-700: #0064bd;
  --blue-800: #0054a3;
  --blue-900: #004487;
  --powder-050: #f4f8fb;
  --powder-100: #e1ecf4;
  --powder-200: #d1e5f1;
  --powder-300: #b3d3ea;
  --powder-400: #a0c7e4;
  --powder-500: #7aa7c7;
  --powder-600: #5b8db1;
  --powder-700: #39739d;
  --powder-800: #2c5777;
  --powder-900: #1e3c52;
  --green-025: #eef8f1;
  --green-050: #dcf0e2;
  --green-100: #cae8d4;
  --green-200: #a6d9b7;
  --green-300: #82ca9a;
  --green-400: #5eba7d;
  --green-500: #48a868;
  --green-600: #3d8f58;
  --green-700: #2f6f44;
  --green-800: #29603b;
  --green-900: #1e472c;
  --yellow-050: #fdf7e3;
  --yellow-100: #fbf2d4;
  --yellow-200: #f1e5bc;
  --yellow-300: #e6d178;
  --yellow-400: #e9c63f;
  --yellow-500: #ddb624;
  --yellow-600: #cea51b;
  --yellow-700: #b89516;
  --yellow-800: #9f8010;
  --yellow-900: #826a0b;
  --red-050: #fdf3f4;
  --red-100: #f9d3d7;
  --red-200: #f4b4bb;
  --red-300: #e87c87;
  --red-400: #de535e;
  --red-500: #d1383d;
  --red-600: #c02d2e;
  --red-700: #ac2726;
  --red-800: #942121;
  --red-900: #7a1819;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --forground-color: #333;
  --blue-40: #45a1ff;
  --blue-40-a10: rgb(69, 161, 255, 0.1);
  --blue-50: #0a84ff;
  --blue-50-a30: rgba(10, 132, 255, 0.3);
  --blue-60: #0060df;
  --blue-70: #003eaa;
  --blue-80: #002275;
  --grey-20: #ededf0;
  --grey-30: #d7d7db;
  --grey-40: #b1b1b3;
  --grey-60: #4a4a4f;
  --grey-90: #0c0c0d;
  --grey-90-a10: rgba(12, 12, 13, 0.1);
  --grey-90-a20: rgba(12, 12, 13, 0.2);
  --grey-90-a30: rgba(12, 12, 13, 0.3);
  --grey-90-a50: rgba(12, 12, 13, 0.5);
  --grey-90-a60: rgba(12, 12, 13, 0.6);
  --green-50: #30e60b;
  --green-60: #12bc00;
  --green-70: #058b00;
  --green-80: #006504;
  --green-90: #003706;
  --orange-50: #ff9400;
  --red-50: #ff0039;
  --red-50-a30: rgba(255, 0, 57, 0.3);
  --red-60: #d70022;
  --red-70: #a4000f;
  --red-80: #5a0002;
  --red-90: #3e0200;
  --yellow-50: #ffe900;
  --yellow-60: #d7b600;
  --yellow-60-a30: rgba(215, 182, 0, 0.3);
  --yellow-70: #a47f00;
  --yellow-80: #715100;
  --yellow-90: #3e2800;
  --shadow-10: 0 1px 4px var(--grey-90-a10);
  --shadow-30: 0 4px 16px var(--grey-90-a10);
  --card-padding: 16px;
  --card-shadow: var(--shadow-10);
  --card-outline-color: var(--grey-30);
  --card-shadow-hover: var(--card-shadow), 0 0 0 5px var(--card-outline-color);
  --gm-background-color: #fff;
  --gm-body-text-color: #3c4043;
  --gm-caption-color: #5f6368;
  --gm-error-color-highlight: #fce8e6;
  --sxr-error-color: #c5221f;
  --gm-hairline-color: #dadce0;
  --gm-hint-color: #80868b;
  --gm-label-color: #5f6368;
  --gm-navigation-icon-color: #3c4043;
  --gm-neutral-highlight-color: #f8f9fa;
  --gm-neutral-outline-color: #dadce0;
  --global-border-radius: 8px;
  --color-1: #333333;
  --color-1-a: rgba(#4c7ebf, 70%);
  --hue-web-blue-50-a10:#fff;
  --color-2: #f25022;
  --color-3: #434242;
  --gradient: linear-gradient(180deg, #F743B6 5.22%, #FCC043 99.75%)
  --scrollbarBG: #a1a1a1;
  --link-color: var(--blue-40);
  --thumbBG: #f8f9fa;
  --ff-sans: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Liberation Sans', sans-serif;
  --ff-serif: Georgia, Cambria, 'Times New Roman', Times, serif;
  --ff-mono: ui-monospace, 'Cascadia Mono', 'Segoe UI Mono', 'Liberation Mono',
    Menlo, Monaco, Consolas, monospace;
  --ff-iransans: IRANSans;
  --theme-body-font-family: var(--ff-sans);
  --body-background-color: #f3f3f3;
}

$color-1: #4c7ebf;
$primary-color: #4c7ebf;
$secondary-color: #3fdbeb;
$headerBackgroudColor: #fff;
$asideBackgroudColor: #fff;
$mainBackgroundColor: #fafafa;
$footer-bg-color: #363232;


.color1 {
  color: $primary-color;

}

.color2 {
  color: $secondary-color;
}

.color3 {
  color: var(--third-color);
}