:root {
  --md-source: #002060;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #00174b;
  --md-ref-palette-primary20: #002a78;
  --md-ref-palette-primary25: #133684;
  --md-ref-palette-primary30: #234290;
  --md-ref-palette-primary35: #314e9c;
  --md-ref-palette-primary40: #3e5aa9;
  --md-ref-palette-primary50: #5873c4;
  --md-ref-palette-primary60: #728de0;
  --md-ref-palette-primary70: #8da8fd;
  --md-ref-palette-primary80: #b4c5ff;
  --md-ref-palette-primary90: #dbe1ff;
  --md-ref-palette-primary95: #eef0ff;
  --md-ref-palette-primary98: #faf8ff;
  --md-ref-palette-primary99: #fefbff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #001e2b;
  --md-ref-palette-secondary20: #003548;
  --md-ref-palette-secondary25: #004058;
  --md-ref-palette-secondary30: #004d67;
  --md-ref-palette-secondary35: #005978;
  --md-ref-palette-secondary40: #006688;
  --md-ref-palette-secondary50: #0080aa;
  --md-ref-palette-secondary60: #1e9bcb;
  --md-ref-palette-secondary70: #49b6e8;
  --md-ref-palette-secondary80: #76d1ff;
  --md-ref-palette-secondary90: #c2e8ff;
  --md-ref-palette-secondary95: #e2f3ff;
  --md-ref-palette-secondary98: #f5faff;
  --md-ref-palette-secondary99: #fbfcff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #001e2f;
  --md-ref-palette-tertiary20: #00344d;
  --md-ref-palette-tertiary25: #00405d;
  --md-ref-palette-tertiary30: #004c6e;
  --md-ref-palette-tertiary35: #00587f;
  --md-ref-palette-tertiary40: #006590;
  --md-ref-palette-tertiary50: #007fb4;
  --md-ref-palette-tertiary60: #3499d1;
  --md-ref-palette-tertiary70: #56b4ee;
  --md-ref-palette-tertiary80: #88ceff;
  --md-ref-palette-tertiary90: #c8e6ff;
  --md-ref-palette-tertiary95: #e5f2ff;
  --md-ref-palette-tertiary98: #f6faff;
  --md-ref-palette-tertiary99: #fbfcff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #1b1b1f;
  --md-ref-palette-neutral20: #303034;
  --md-ref-palette-neutral25: #3b3b3f;
  --md-ref-palette-neutral30: #46464a;
  --md-ref-palette-neutral35: #525256;
  --md-ref-palette-neutral40: #5e5e62;
  --md-ref-palette-neutral50: #77767a;
  --md-ref-palette-neutral60: #919094;
  --md-ref-palette-neutral70: #acaaaf;
  --md-ref-palette-neutral80: #c7c6ca;
  --md-ref-palette-neutral90: #e4e2e6;
  --md-ref-palette-neutral95: #f2f0f4;
  --md-ref-palette-neutral98: #fbf8fd;
  --md-ref-palette-neutral99: #fefbff;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #191b23;
  --md-ref-palette-neutral-variant20: #2e3038;
  --md-ref-palette-neutral-variant25: #393b43;
  --md-ref-palette-neutral-variant30: #45464f;
  --md-ref-palette-neutral-variant35: #51525a;
  --md-ref-palette-neutral-variant40: #5d5e67;
  --md-ref-palette-neutral-variant50: #757680;
  --md-ref-palette-neutral-variant60: #8f909a;
  --md-ref-palette-neutral-variant70: #aaaab4;
  --md-ref-palette-neutral-variant80: #c5c6d0;
  --md-ref-palette-neutral-variant90: #e2e2ec;
  --md-ref-palette-neutral-variant95: #f0f0fa;
  --md-ref-palette-neutral-variant98: #faf8ff;
  --md-ref-palette-neutral-variant99: #fefbff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #3e5aa9;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #dbe1ff;
  --md-sys-color-on-primary-container-light: #00174b;
  --md-sys-color-secondary-light: #006688;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #c2e8ff;
  --md-sys-color-on-secondary-container-light: #001e2b;
  --md-sys-color-tertiary-light: #006590;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #c8e6ff;
  --md-sys-color-on-tertiary-container-light: #001e2f;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fefbff;
  --md-sys-color-on-background-light: #1b1b1f;
  --md-sys-color-surface-light: #fefbff;
  --md-sys-color-on-surface-light: #1b1b1f;
  --md-sys-color-surface-variant-light: #e2e2ec;
  --md-sys-color-on-surface-variant-light: #45464f;
  --md-sys-color-outline-light: #757680;
  --md-sys-color-inverse-on-surface-light: #f2f0f4;
  --md-sys-color-inverse-surface-light: #303034;
  --md-sys-color-inverse-primary-light: #b4c5ff;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #3e5aa9;
  --md-sys-color-surface-tint-color-light: #3e5aa9;
  /* dark */
  --md-sys-color-primary-dark: #b4c5ff;
  --md-sys-color-on-primary-dark: #002a78;
  --md-sys-color-primary-container-dark: #234290;
  --md-sys-color-on-primary-container-dark: #dbe1ff;
  --md-sys-color-secondary-dark: #76d1ff;
  --md-sys-color-on-secondary-dark: #003548;
  --md-sys-color-secondary-container-dark: #004d67;
  --md-sys-color-on-secondary-container-dark: #c2e8ff;
  --md-sys-color-tertiary-dark: #88ceff;
  --md-sys-color-on-tertiary-dark: #00344d;
  --md-sys-color-tertiary-container-dark: #004c6e;
  --md-sys-color-on-tertiary-container-dark: #c8e6ff;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #1b1b1f;
  --md-sys-color-on-background-dark: #e4e2e6;
  --md-sys-color-surface-dark: #1b1b1f;
  --md-sys-color-on-surface-dark: #e4e2e6;
  --md-sys-color-surface-variant-dark: #45464f;
  --md-sys-color-on-surface-variant-dark: #c5c6d0;
  --md-sys-color-outline-dark: #8f909a;
  --md-sys-color-inverse-on-surface-dark: #1b1b1f;
  --md-sys-color-inverse-surface-dark: #e4e2e6;
  --md-sys-color-inverse-primary-dark: #3e5aa9;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #b4c5ff;
  --md-sys-color-surface-tint-color-dark: #b4c5ff;
  /* Custom Colors */
  --md-custom-color-Custom-Color-1: #85023b;
  --md-custom-color-Custom-Color-2: #3d7aae;
  --md-custom-color-Custom-Color-3: #cc9402;
  --md-custom-color-Custom-Color-4: #1a0a7a;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  --md-sys-typescale-label-small-line-height: 16px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  --md-sys-typescale-label-medium-line-height: 16px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  --md-sys-typescale-label-large-line-height: 20px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  --md-sys-typescale-body-small-line-height: 16px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  --md-sys-typescale-body-medium-line-height: 20px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  --md-sys-typescale-body-large-line-height: 24px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  --md-sys-typescale-headline-small-line-height: 32px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  --md-sys-typescale-headline-large-line-height: 40px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  --md-sys-typescale-display-small-line-height: 44px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  --md-sys-typescale-display-medium-line-height: 52px;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  --md-sys-typescale-display-large-line-height: 64px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
  --md-sys-typescale-title-small-line-height: 20px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  --md-sys-typescale-title-medium-line-height: 24px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  --md-sys-typescale-title-large-line-height: 28px;
}
