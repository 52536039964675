@use 'font' as *;

body {
  font-family: calluna;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
      Bitstream Vera Sans Mono, Courier New, monospace;
}

strong,
.copyright p {
  font-family: 'Lato', sans-serif;
}

.copyright p {
  font-weight: 300;
}

h1 {
  font-weight: 400;
}


small,
.text_small {
  font-size: 0.8rem;
}