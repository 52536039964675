@use "sass:color";
@use 'variables' as *;

// Card
.card {
    position: relative;
    word-wrap: break-word;
    margin-top: 0.8rem;
    background-color: var(--white);
    margin-bottom: 16px;
    color: #4a4a4a;

 
 

    &:not([data-border="no"]) {
        border-radius: 6px;
    }

    &:not([data-shadow='0']) {
        box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
    }

    &[data-theme="dark"] {
        background: #2b2a33;
    }

    .card__header {
        font-weight: 400;
        color: #3f414d;
        background-color: color.scale($primary-color, $lightness: 90%);

        &:not([data-padding='no'], [data-padding='0']) {
            padding: 1.4rem 1.25rem;
        }

    }

    .card__body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        line-height: 1.5;

        &:not([data-padding="0"]) {
            padding: 1rem 1.2rem;
        }
    }

    &:hover:not([data-shadow="0"]) {}

    &[data-margin="auto"] {
        max-width: 480px;
        margin: 0 auto;
    }

    &:hover[data-shadow="0"] {
        border-radius: 4px;
        overflow: hidden;
        transform: translateY(-2px);
    }
}