$r: 80px;

// Loading
.loading {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column: 1;
  position: absolute;
  inset: 0;
  height: 100%;
  display: grid;
  place-content: center;
  background-color: var(--white);
  z-index: 999;
  transition: all 0.5s ease-in-out;

  &:before {
    content: '';
    width: $r;
    height: $r;
    border-style: solid;
    border-color: var(--color-1);
    border-width: 2px 0 0 0;
    border-radius: 100px;
    -webkit-animation: spinner 1s linear 0s infinite normal none running;
    animation: spinner 1s linear 0s infinite normal none running;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('./logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 3em;
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}