@use 'reset' as *;
@use 'variables' as *;
@use 'flex' as *;
@use 'text' as *;
@use 'form' as *;
@use 'layout' as *;
@use 'typography' as *;
@use 'card' as *;
@use 'loading' as *;

@use 'material-theme' as *;
// @use '../../node_modules/office-ui-fabric-core/dist/sass/Fabric.scss' as *;

// Fondation
html,
body {
    overflow-x: hidden;
}

header,
footer {
    ul {
        list-style-type: none;
        *margin: 0;
        padding: 0;
    }

}

a {
    text-decoration: none;
}

article {
    height: 100%;
}

.relative {
    position: relative;
}

.container {}

// Frame
div[class*='__frame'] {
    margin: 0 auto;
    height: inherit;

    &[data-width='small'] {
        max-width: 479px;
    }

    &[data-width='medium'] {
        max-width: 639px;
    }

    &[data-width='large'] {
        max-width: 1023px;
    }

    &[data-width='custom'] {
        max-width: 1203px;
    }

    &[data-width='xlarge'] {
        max-width: 1365px;
    }

    &[data-width='xxlarge'] {
        max-width: 1919px;
    }

    &[data-width='xxxlarge'] {
        max-width: 100%;
    }
}

:root {
    --font-size-base: clamp(1rem, 0.34vw + 0.91rem, 1.19rem);
    --font-size-lg: clamp(1.2rem, 0.7vw + 1.2rem, 1.5rem);
    --font-size-xl: clamp(1.1rem, 1.18vw + 1.85rem, 3.75rem);

    --color-text: hsla(0, 39%, 95%, 0.9);
    --color-bg: #fff;
    --color-border: hsl(17, 24%, 90%);
    --link-gradient: linear-gradient(45deg, #9eeff7, #17a8d4 30%, var(--color-border) 60%);
    --font-family: system-ui, sans-serif;
    --frosty-gradient: linear-gradient(0deg, #9eeff7 1.2%, #17a8d4 70.75%);

    --primary-color: #002060;
    --secondary-color: #005E7E;
}

html,
body {
    min-height: 100vh;
    height: 100%;
}

// Html
html {
    font-size: var(--font-size-base);
    background-color: var(--color-bg);
    scroll-behavior: smooth;
}

// Body
body {
    margin: 0;
    background-color: var(--color-bg);
}

//v
.motion {
    background-color: var(--white);
    position: fixed;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: grid;
    place-items: center;
}

video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 999;
}

// root
#root {
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-areas:
        'header'
        'main'
        'footer';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;

        background-position: center;
        background-size: cover;
        backdrop-filter: blur(4px);
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgba($color: #000000, $alpha: .33);
        z-index: -1;
    }
}

// Header, Main, Footer
header,
main,
footer {
    position: relative;
    *place-self: center;

}

// Header
header {
    grid-area: header;
    background: #FFFFFF;
    border-bottom: 9px solid var(--primary-color);
    position: relative;

    figure {
        cursor: pointer;

        img {
            width: 311px;
        }
    }

    .pc-nav {
        li {
            margin-left: 1rem;

            a,
            .nav-btn {
                color: #343434;
                font-size: 1.2rem;
            }
        }
    }

    @media (max-width: 576px) {
        height: 90px;
        padding: .6rem .5rem;


        .pc-nav {
            display: none;
        }

        figure {
            img {
                width: 211px;
            }
        }
    }

    @media (min-width: 576px) {
        height: 120px;
        padding: .6rem 0;

        .menu {
            display: none;
        }


        figure {
            img {
                width: 311px;
            }
        }
    }

    i {
        user-select: none;
        font-size: 3.5rem !important;
        cursor: pointer;
    }

    nav {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 9;
        background-color: var(--white);
        display: none;
        height: 0;
        transition: all 200ms linear;
        border-bottom: 9px solid var(--primary-color);

        ul {
            li {
                width: 50%;
                display: inline-block;
                min-height: 6rem;
                vertical-align: middle;
                padding: 1rem;



                p {
                    max-width: 400px;
                    color: var(--black);
                    font-family: calluna;
                }

                @media (max-width: 576px) {
                    display: block;
                    width: 100%;

                    p {
                        max-width: 100% !important;
                    }

                }

                .nav-btn {
                    display: block;
                    font-size: .8rem;
                }

                span {
                    font-weight: 900;
                    color: var(--primary-color)
                }
            }
        }
    }

    .show {
        display: block !important;
        height: auto;
        transition: all 200ms linear;
    }
}

// Main
main {
    grid-area: main;

    article {
        section {
            position: relative;
        }
    }

    h1.welcome,
    h2.slogan {
        text-align: center;
    }

    p.message {
        line-height: 1.6;
        margin: 1rem 0;
        background: rgba($color: #fff, $alpha: .10);
        padding: 1.0rem;
        border-radius: 0.4rem;
        color: var(--color-bg);
    }
}

// Footer
footer {
    grid-area: footer;
    color: #1a1a1a;
    padding: 2rem 1rem 0 1rem;
    background: #cc9402;

    a {
        color: var(--white)
    }

    figure {
        img {
            max-width: 300px;
        }

        @media (min-width: 500px) {
            img {
                margin: 0 auto;
            }
        }
    }

    p {
        color: var(--white);
    }

    ul {
        @media (max-width: 500px) {
            flex-direction: row !important;
            justify-content: space-between;
            flex-grow: 1 !important;
        }

        li {
            a {
                display: block;
                font-weight: 300;
                margin-top: .5rem;
            }
        }
    }

    p {
        margin: 2rem 0;
    }

    .copyright {
        padding: 1.1rem 0;


        @media (max-width: 500px) {
            a {
                text-align: center;
            }
        }

        @media (min-width: 500px) {
            a {
                text-align: right;
            }
        }
    }
}


// Heading
.heading {
    padding: 2rem 6rem;
    color: var(--white);

    @media (max-width: 500px) {
        padding: 2rem 2.5rem;
    }

    h1 {
        margin-bottom: 1rem;

    }

    p {
        font-weight: 300;
        text-transform: initial;
    }
}

// about-popup
.popup {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column: 1/1;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    z-index: 999;


    .content {
        position: relative;
        height: calc(100vh - 3rem * 2);
        margin: 3rem 1rem;
        background: #FFFFFF;
        border: 1px solid #CAD6E2;
        border-radius: 16px;
        color: #333;
        padding: 2em;

        h1 {
            margin-bottom: 2rem;
        }

        .close {
            position: absolute;
            left: calc(50% - 40px);
            top: -40px;
            width: 80px;
            height: 80px;
            padding: 1rem;
            background: #F7F8FA;
            box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.25);
            border-radius: 44px;
            display: grid;
            place-items: center;
            cursor: pointer;
        }
    }
}


.grid__item {
    * {
        z-index: 2;
    }
}

.link-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(34ch, 1fr));
    gap: 1rem;
    padding: 0;


    li {
        list-style: none;
        display: flex;
        padding: 0.15rem;
        background-image: var(--link-gradient);
        background-size: 400%;
        border-radius: 0.5rem;
        background-position: 100%;
        transition: background-position 0.6s cubic-bezier(0.22, 1, 0.36, 1);

        &:is(:hover, :focus-within) {
            background-position: 0;

            h2 {
                color: #fff;
            }

            span {
                transform: translateX(2px);
            }
        }

        a {
            width: 100%;
            text-decoration: none;
            line-height: 1.4;
            padding: .5em 1.3em;
            border-radius: 0.35rem;
            color: #333;
            border: 3px solid #17a8d4;
            opacity: 0.8;
        }

        h2 {
            margin: 0;
            transition: color 0.6s cubic-bezier(0.22, 1, 0.36, 1);

            span {
                display: inline-block;
                transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
            }
        }

        p {
            margin-top: 0.75rem;
            margin-bottom: 0;
        }
    }
}

.full-width {
    width: 100%;
}

.text-gradient {
    font-weight: 900;
    background-image: var(--frosty-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100% 200%;
    background-position-y: 100%;
    border-radius: 0.4rem;
    animation: pulse 4s ease-in-out infinite;
}

@keyframes pulse {

    0%,
    100% {
        background-position-y: 0%;
    }

    50% {
        background-position-y: 80%;
    }
}