@media (min-width: 512px) {
    .text-xs-left {
      text-align: left !important;
    }
    .text-xs-right {
      text-align: right !important;
    }
    .text-xs-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 768px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1024px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1280px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1536px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  
  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
  }
  
  .text-justify {
    text-align: justify !important;
  }
  
  .text-nowrap {
    white-space: nowrap !important;
  }
  
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .text-through {
    text-decoration: line-through;
  }
  
  .text-underline {
    text-decoration: underline;
  }
  
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-lowercase {
    text-transform: lowercase !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  .text-capitalize {
    text-transform: capitalize !important;
  }
  .font-weight-light {
    font-weight: 300 !important;
  }
  .font-weight-normal {
    font-weight: 400 !important;
  }
  .font-weight-bold {
    font-weight: 700 !important;
  }
  .font-italic {
    font-style: italic !important;
  }
  .text-white {
    color: #fff !important;
  }
  .text-primary {
    color: #007bff !important;
  }
  a.text-primary:focus,
  a.text-primary:hover {
    color: #0062cc !important;
  }
  .text-secondary {
    color: #6c757d !important;
  }
  a.text-secondary:focus,
  a.text-secondary:hover {
    color: #545b62 !important;
  }
  .text-success {
    color: #28a745 !important;
  }
  a.text-success:focus,
  a.text-success:hover {
    color: #1e7e34 !important;
  }
  .text-info {
    color: #17a2b8 !important;
  }
  a.text-info:focus,
  a.text-info:hover {
    color: #117a8b !important;
  }
  .text-warning {
    color: #ffc107 !important;
  }
  a.text-warning:focus,
  a.text-warning:hover {
    color: #d39e00 !important;
  }
  .text-danger {
    color: #dc3545 !important;
  }
  a.text-danger:focus,
  a.text-danger:hover {
    color: #bd2130 !important;
  }
  .text-light {
    color: #f8f9fa !important;
  }
  a.text-light:focus,
  a.text-light:hover {
    color: #dae0e5 !important;
  }
  .text-dark {
    color: #343a40 !important;
  }
  a.text-dark:focus,
  a.text-dark:hover {
    color: #1d2124 !important;
  }
  .text-body {
    color: #212529 !important;
  }
  .text-muted {
    color: #6c757d !important;
  }
  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  